export default {
  settings: 'Настройки',
  domains: 'Домены',
  tracking_domains: 'Трекинг-домены',
  categories: 'Категории',
  trafficback: 'Trafficback',
  offers: 'Офферы',
  event_group: 'Группа событий',
  event_groups: 'Группы событий',
  goal_group: 'Группа целей',
  goal_groups: 'Группы целей',
  security: 'Безопасность',
  security_use_whitelist: 'Использовать белый список IP-адресов для постбеков',
  security_use_whitelist_help_1:
    'Будут разрешены постбеки с этих IP-адресов. Вводите по одному IP-адресу на строку.',
  security_use_whitelist_help_2: 'Допустимы одиночные IP. Пример:',
  security_2fa_enable: 'Включить двухфакторную проверку',
  security_2fa_admin:
    'Включить двухфакторную проверку для кабинета администратора',
  security_2fa_partner: 'Включить двухфакторную проверку для кабинета партнера',
  two_factor_auth_enter_code: 'Введите код',
  two_factor_auth_enter_code_hint: 'Код подтверждения отправлен на почту',
  two_factor_auth_enter_code_ga_hint:
    'Код подтверждения отправлен на Google Authenticator',
  two_factor_auth_enter_get_new_code: 'Получить новый код можно через',
  two_factor_auth_enter_send_new_code: 'Отправить новый код',
  contacts: 'Добавление контактов администратора',
  partner_advertiser_contacts: 'Контакты партнёра/рекламодателя',
  integrations: 'Интеграции',
  tags: 'Теги',
  general_settings: 'Основные настройки',
  settings_successfully_updated: 'Настройки успешно обновлены',
  settings_invoices_successfully_updated:
    'Настройки автогенерации счетов успешно обновлены',
  general_settings_name: 'Название партнерской сети',
  general_settings_logo: 'Логотип партнерской сети',
  general_settings_favicon: 'Иконка партнерской сети',
  general_contacts: 'Общие контакты партнерской сети',
  general_contacts_help:
    'Данные контакты отображаются на странице авторизации у неактивированных пользователей',
  general_contacts_limit: 'Вы достигли лимита в {{count}} контактов',
  technical_support_email: 'Электронная почта технической поддержки',
  users: 'Регистрация пользователей',
  default_manager: 'Менеджер по умолчанию',
  default_manager_empty: 'Не задан',
  users_sending_email:
    'Обязательное подтверждение почты рекламодателем и партнером',
  users_moderation: 'Модерация рекламодателя и партнера',
  recommendation_horizontal_logo:
    'Рекомендуем загружать горизонтальные логотипы с соотношением сторон 3:1',
  currencies: 'Валюты',
  no_currencies: 'Нет активных валют',
  currency_code: 'Код валюты',
  rate: 'Курс',
  is_enabled: 'Активна',
  currency_successfully_updated: 'Валюта успешно обновлена',
  main_domains: 'Основные домены',
  admin_panel_url: 'URL панели администратора',
  partner_panel_url: 'URL панели партнера',
  advertiser_panel_url: 'URL панели рекламодателя',
  documents: 'Документы',
  terms_of_use: 'Условия использования',
  privacy_policy: 'Политика конфиденциальности',
  show_terms_of_use: 'Показывать условия использования для партнёров?',
  show_privacy_policy: 'Показывать политику конфиденциальности для партнёров?',
  save_with_partners_acceptance:
    'Сохранить с обязательным принятием партнерами',
  save_with_partners_acceptance_help:
    'После сохранения все партнеры должны будут принять обновленные условия использования и политику конфиденциальности',
  save_with_partners_acceptance_confirm:
    'Уверены, что хотите сохранить с обязательным принятием партнерами обновленных документов?',
  resale_period: 'Период отсутствия активности (в днях)',
  referral_program: 'Реферальная программа',
  referral_percent: 'Процент реферальной программы',
  payment_systems: 'Платежные системы',
  payments: 'Выплаты',
  thresholds: 'Минимальная сумма вывода средств',
  thresholds_hint:
    'Минимальная сумма для счета. Для каждой валюты свой минимальный порог.',
  invoices: 'Генерация счетов',
  invoices_hint: 'Любой счет администратор может сформировать вручную',
  invoices_partner: 'Партнер может формировать счёт самостоятельно',
  invoices_partner_hint:
    'Партнер сможет самостоятельно формировать счета на вывод средств из своего личного кабинета.',
  invoice_generation_method: 'Способы генерации',
  invoices_autogenerate: 'Автогенерация',
  invoices_autogenerate_help:
    'Счета будут формироваться автоматически для всех активных партнеров в зависимости от выбранных настроек периодичности и офферов.',
  invoices_autogenerate_week: 'Раз в неделю',
  invoices_autogenerate_month: 'Раз в месяц',
  invoices_autogenerate_month_twice: 'Два раза в месяц',
  invoices_autogenerate_partners: 'Партнёры',
  invoices_autogenerate_offers: 'Офферы',
  invoices_autogenerate_week_day_1: 'Понедельник',
  invoices_autogenerate_week_day_2: 'Вторник',
  invoices_autogenerate_week_day_3: 'Среда',
  invoices_autogenerate_week_day_4: 'Четверг',
  invoices_autogenerate_week_day_5: 'Пятница',
  invoices_autogenerate_week_day_6: 'Суббота',
  invoices_autogenerate_week_day_7: 'Воскресенье',
  invoices_autogenerate_week_every_day_1: 'Каждый Понедельник',
  invoices_autogenerate_week_every_day_2: 'Каждый Вторник',
  invoices_autogenerate_week_every_day_3: 'Каждую Среду',
  invoices_autogenerate_week_every_day_4: 'Каждый Четверг',
  invoices_autogenerate_week_every_day_5: 'Каждую Пятницу',
  invoices_autogenerate_week_every_day_6: 'Каждую Субботу',
  invoices_autogenerate_week_every_day_7: 'Каждое Воскресенье',
  invoices_day_picker_help:
    'Если в месяце нет выбранного числа, то счет будет формироваться в последний день месяца.',
  invoices_on_nth_day_one: '{{day}}-го числа',
  invoices_on_nth_day_two: '{{day}}-го числа',
  invoices_on_nth_day_few: '{{day}}-го числа',
  invoices_on_nth_day_other: '{{day}}-го числа',
  active_user_criteria: 'Критерий активного пользователя',
  active_user_help:
    'Активный пользователь - это пользователь, который в указанный промежуток времени совершил целевое действие из выбранной группы целей. Активные пользователи будут определяться в зависимости от выбранной группы целей. Используется для когортного анализа.',
  trafficback_url: 'Trafficback URL',
  trafficback_url_hint:
    'Если трафик не соответствует параметрам, он будет перенаправлен на данный URL. В ссылке могут быть указаны макросы:\n\n{click_id} - уникальный идентификатор клика;\n{partner_id} - ID партнера;\n{sub_id1} - sub_id #1, можно добавить до 10 sub_id;\n{offer_id} - ID оффера;\n{creative_id} - идентификатор промо-материала.',
  promocodes: 'Промокоды',
  max_promocode_count:
    'Количество свободных промокодов в оффере, которые партнер может получить самостоятельно',
  promo_code_postback_priority: 'Приоритет обработки постбека',
  conversion_settings: 'Работа с конверсиями',
  conversion_settings_partners_label:
    'Сделать макрос {value} и {value_currency} доступным для следующих партнеров',
  conversion_settings_partners_help:
    'Для выбранных партнеров в локальных и глобальных постбеках будет доступна отправка значения поля value и его валюта',
  conversion_settings_hide_reasons_title:
    'Скрыть у партнеров отклоненные конверсии:',
  conversion_settings_not_hide: 'Не скрывать',
  conversion_settings_hide_all: 'Все',
  conversion_settings_hide_reasons: 'С причинами:',
  show_custom_fields_for_partner: 'Показывать партнерам следующие параметры:',
  setting_name: 'Название',
  global_postbacks: 'Глобальные постбеки',
  add_global_postback: 'Добавить глобальный постбек',
  global_postback_always_send: 'Отправлять с локальным постбеком в оффере',
  is_conditions_hidden_to_partner:
    'Скрыть общие условия у партнера, если присутствует хотя бы одно индивидуальное условие',
  conversions_by_hidden_conditions:
    'Использовать конверсии, созданные по скрытым от партнерам условиям, для расчета и формирования общей статистики',
  is_referral_email_hidden_to_partner: 'Скрыть у партнеров email рефералов',
  cookies: 'Работа с Cookies',
  cookie_lifetime: 'Время жизни Cookie в днях',
  cookies_help:
    'Для активации времени жизни Cookies скопируйте и вставьте приведенный js-скрипт на сайт. Код должен размещаться в разделе <body></body> вашего сайта.',
  add_currency: 'Добавить валюту',
  ga_qr_text:
    'Для подключения двухфакторной аутентификации Google Authenticator  установите приложение через AppStore или GoogleMarket и отсканируйте QR-код. ',
  ga_text: 'Для ручного добавления укажите следующие данные:',
  ga_connected_label: 'Устройство подключено',
  ga_connected_text:
    'Чтобы изменить тип двухфакторной проверки, отключите устройство.',
  ga_secret_key: 'secret_key',
  ga_connect: 'Подключить',
  ga_disconnect: 'Отключить',
  timezone: 'Часовая зона',
  menu_settings_title: 'Дополнительные ссылки в меню партнера',
  menu_settings_title_help: 'Эти ссылки будут отображаться в меню партнёра',
  add_menu_item: 'Добавить элемент меню',
  menu_setting_adding_modal_title: 'Добавление пункта меню',
  menu_setting_editing_modal_title: 'Изменение пункта меню',
  no_menu_items: 'Нет пунктов меню',
  menu_settings_remove_confirm: 'Вы уверены, что хотите удалить пункт меню?',
  menu_settings_name: 'Название пункта меню',
  menu_settings_link: 'Ссылка',
  menu_settings_color: 'Цвет',
  menu_settings_successfuly_created: 'Пункт меню успешно добавлен',
  menu_settings_successfuly_updated: 'Пункт меню успешно обновлён',
  menu_settings_limit: 'Вы достигли лимита в {{count}} пункта меню',
  color_black: 'Чёрный',
  color_white: 'Белый',
  color_red: 'Красный',
  color_orange: 'Оранжевый',
  color_green: 'Зелёный',
  'color_blue-green': 'Сине-зелёный',
  color_purple: 'Фиолетовый',
  'color_light-red': 'Светло красный',
  'color_light-orange': 'Светло оранжевый',
  'color_light-green': 'Светло зелёный',
  'color_light-blue-green': 'Светло сине-зеленый',
  'color_light-purple': 'Светло фиолетовый',
  color_blue: 'Синий',
  color_yellow: 'Желтый',
  color_gray: 'Серый',
  is_hmac_activated:
    'Включить проверку подписи в постбеках на базе алгоритма HMAC',
};
