export default {
  'maximum length of a role name is no more than 255 characters':
    'La longitud máxima del nombre de rol no es más de 255 caracteres',
  'enter role name': 'Introduzca el nombre del rol',
  required: 'Obligatorio de rellenar',
  'field length from 2 to 200 characters':
    'Longitud de campo de 2 a 200 caracteres',
  'field length from 2 to 3000 characters':
    'Longitud de campo de 2 a 3000 caracteres',
  'field length from 3 to 60 characters':
    'Longitud de campo de 3 a 60 caracteres',
  'field length from 1 to 40 characters':
    'Longitud de campo de 1 a 40 caracteres',
  'field length from 1 to 50 characters':
    'Longitud de campo de 1 a 50 caracteres',
  'field length from 1 to 60 characters':
    'Longitud de campo de 1 a 60 caracteres',
  'field length from 3 to 120 characters':
    'Longitud de campo de 3 a 120 caracteres',
  'field length no more than 200 characters':
    'Longitud del campo de hasta 200 caracteres',
  'field length no more than 255 characters':
    'Longitud máxima del campo 255 caracteres',
  'field length no more than 120 characters':
    'Longitud del campo no superior a 120 caracteres',
  'field length no more than 150 characters':
    'Longitud del campo no superior a 150 caracteres',
  'invalid email address': 'Dirección de correo electrónico no válida',
  'The email has already been taken.': 'Email ya está ocupado',
  'The email has already been taken': 'Email ya está ocupado',
  'The name must be at least 3 characters':
    'El nombre debe tener al menos 3 caracteres.',
  'The value must be a link': 'El valor debe ser una referencia',
  'The name must be no more than 30 characters':
    'El nombre no debe tener más de 30 caracteres',
  'The name must be no more than 60 characters':
    'El nombre no debe tener más de 60 caracteres',
  'The conversion name must be no more than 30 characters':
    'El nombre de la conversión no debe tener más de 30 caracteres',
  'The click lifetime must be from 1 to 1095':
    'El tiempo de vida del clic debe ser de 1 a 1095',
  'The minimum password length must be at least 8 characters':
    'La longitud mínima de la contraseña debe ser de al menos 8 caracteres',
  'The maximum password length must be no more than 255 characters':
    'La longitud máxima de la contraseña no debe ser superior a 255 caracteres',
  'passwords must match': 'Las contraseñas no coinciden',
  'Old password is incorrect': 'Contraseña antigua incorrecta',
  'The ID must be at least 3 characters':
    'La longitud mínima del ID debe ser de 3 caracteres',
  'The ID must be no more than 30 characters':
    'La longitud máxima del ID no debe ser superior a 30 caracteres',
  'The field can contain only latin characters, dot(.), dash(-) and underscore(_)':
    'El campo solo puede contener caracteres latinos, punto (.), Guión (-) y guiones bajos (_)',
  'The key has already been taken.': 'Este ID ya está ocupado',
  'The rules field must have a value.':
    'Seleccione los permisos de acceso para el rol.',
  maximum_5_managers_can_be_assigned:
    'Se puede asignar un máximo de 5 managers',
  the_URL_must_be_at_least_3_characters:
    'La longitud mínima de la URL debe ser de al menos 3 caracteres',
  the_URL_must_be_no_more_than_255_characters:
    'La longitud máxima de la URL debe ser de 255 caracteres',
  the_target_link_must_be_at_least_3_characters:
    'La longitud mínima del enlace de destino debe ser de al menos 3 caracteres',
  the_target_link_must_be_no_more_than_2000_characters:
    'La longitud máxima del enlace de destino no debe ser superior a 2000 caracteres',
  the_link_must_be_no_more_than_2000_characters:
    'La longitud máxima del enlace no debe ser superior a 2000 caracteres',
  field_length_must_be_no_more_than_2000_characters:
    'La longitud máxima del campo no debe ser superior a 2000 caracteres',
  the_number_must_be_integer: 'El número debe ser entero',
  the_number_must_be_at_least_1000000:
    'El número no debe ser mayor que\n1 000 000',
  the_number_must_be_at_least_10: 'El número debe ser al menos 10',
  the_number_must_be_from_10_to_1000000: 'El número debe ser de 10 a 1 000 000',
  the_number_must_be_from_1_to_1000000: 'El número debe ser de 1 a 1 000 000',
  the_number_must_be_from_0_to_100: 'El número debe ser de 0 a 100',
  tracking_domain_wrong_format: 'Formato de nombre de dominio incorrecto',
  tracking_domain_without_click:
    'Ingresaste un valor incorrecto en el campo. Proporcione un enlace sin el prefijo "hacer clic"',
  hold_period_must_be_from_0_to_60:
    'El período de retención puede ser de 0 a 60',
  postclick_must_be_from_1_to_1825: 'Postclick puede ser de 1 a 1825 (5 años)',
  the_field_must_be_filled_in_or_the_flag_is_unlimited:
    'El campo debe ser rellenado o marcado como Ilimitado',
  the_field_must_be_filled_in_or_the_flag_is_use_hold_period:
    'El campo debe ser llenado o la bandera no está establecida en "Período de retención"',
  only_Latin_letters_are_allowed:
    'Solo se permiten letras, números y caracteres latinos',
  ID_must_be_unique_within_the_offer:
    'El ID debe ser único dentro de la oferta',
  countries: 'Países:',
  used_in_other_conditions: 'se utilizan en otras condiciones',
  target_link_wrong_params: 'Macros mal escritas',
  at_least_one_goal_must_be_added: 'Debe añadirse al menos un objetivo.',
  at_least_one_country_must_be_added: 'Debe añadirse al menos un país.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_event:
    'No es posible calcular el porcentaje para el pago de la recompensa por un evento no monetario.',
  you_cannot_set_a_SUM_counting_model_for_a_single_goal_with_a_non_monetary_event:
    'No se puede especificar un modelo de conteo SUM para un solo objetivo con un evento no monetario.',
  you_cannot_set_multiple_payout_because_sum_parameter_can_be_reachable_only_once:
    'No se puede especificar la opción de pago múltiple, ya que la opción de cantidad solo se puede alcanzar una vez.',
  it_is_impossible_to_calculate_the_percentage_of_payout_for_sum_counting_model:
    'No se puede calcular el porcentaje de recompensa para el modelo de conteo SUM.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_events:
    'No es posible calcular el porcentaje para el pago de la remuneración por eventos no monetarios.',
  it_is_impossible_to_payout_of_a_fixed_amount_several_times_for_non_recurring_non_monetary_events:
    'No se puede pagar una suma fija varias veces por sucesos no monetarios que no se repitan.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_multiple_goals_with_monetary_events:
    'No es posible calcular el interés para el pago de una remuneración para múltiples propósitos con eventos monetarios.',
  it_is_impossible_to_payout_several_times_because_multiple_goals_can_only_work_once:
    'No se puede pagar la recompensa varias veces, ya que muchos objetivos solo pueden funcionar una vez.',
  you_cannot_set_a_SUM_counting_model_for_a_multiple_goals_with_a_non_monetary_events:
    'No se puede especificar el modelo de conteo SUM para múltiples propósitos con eventos no monetarios',
  the_value_cannot_be_more_than_2_147_483_647:
    'El valor no puede ser mayor que 2 147 483 647',
  the_value_cannot_be_more_than_2_147_483:
    'El valor no puede ser mayor que 2 147 483',
  'the_value_cannot_be_less_than_-2_147_483':
    'El valor no puede ser inferior a -2 147 483',
  the_end_value_must_be_greater_than_or_equal_to_the_start_value:
    'El valor final debe ser mayor o igual que el valor inicial',
  email_length_must_be_no_more_than_255_characters:
    'La longitud de los correos electrónicos no debe superar los 255 caracteres',
  the_value_must_be_at_least_1: 'El valor debe ser al menos 1',
  the_value_must_be_at_least_2147483647:
    'El valor debe ser al menos -2147483647',
  the_value_must_be_no_more_than_2147483647:
    'El valor no debe ser mayor que 2147483647',
  the_name_must_be_no_more_than_128_characters:
    'El nombre no debe tener más de 128 caracteres',
  value_of_the_field_must_be_no_more_than_128_characters:
    'El valor del campo no debe tener más de 128 caracteres',
  the_value_must_be_no_more_than: 'El valor no debe ser mayor',
  category_name_must_be_from_3_to_255:
    'La longitud del nombre de la categoría debe ser de 3 caracteres a 255',
  category_name_wrong_symbols:
    'El nombre de la categoría debe incluir solo los siguientes caracteres: letras, números, puntos, comas, espacios y guiones',
  event_group_name_must_be_from_3_to_60:
    'La longitud del nombre del grupo de eventos debe ser de 3 a 60 caracteres',
  event_group_name_wrong_symbols:
    'El nombre del grupo de eventos solo debe incluir los siguientes caracteres: letras, números, subrayado, espacio y guión',
  goal_group_name_must_be_from_3_to_60:
    'La longitud del nombre del grupo de objetivos debe ser de 3 a 60 caracteres',
  goal_group_key_must_be_from_1_to_60:
    'La longitud de la clave del grupo de objetivos debe ser de 1 a 60 caracteres',
  field_must_be_from_1_to_60:
    'La longitud del campo debe ser de 1 a 60 caracteres',
  goal_group_name_wrong_symbols:
    'El nombre del grupo de objetivos debe incluir solo los siguientes caracteres: letras, números, subrayado, espacio y guión',
  goal_group_key_wrong_symbols:
    'La clave de grupo de objetivos debe incluir solo los siguientes caracteres: letras latinas, números, subrayado y guion',
  field_value_wrong_symbols:
    'El valor del campo debe incluir solo los siguientes caracteres: letras, números, subrayado y guion',
  ips_required: 'Debe completar la lista de direcciones IP',
  ips_wrong: 'Algunas direcciones IP no son válidas',
  ips_limit: 'Ha alcanzado el límite de 100 direcciones IP',
  type_string: 'El valor del campo debe ser una cadena',
  type_number: 'El valor del campo debe ser un número',
  the_field_value_must_be_greater_than_zero:
    'El valor del campo debe ser mayor que 0',
  the_field_value_must_be_greater_than_or_equal_to_zero:
    'El valor del campo debe ser mayor o igual a 0',
  'The comment must be a string.': 'El comentario debe ser una cadena.',
  'The comment must be at least 1 characters.':
    'El comentario debe tener al menos un carácter.',
  'The comment may not be greater than 255 characters.':
    'El comentario no puede tener más de 255 caracteres.',
  field_length_must_be_at_least_1_character:
    'La longitud del campo no debe ser inferior a 1 carácter',
  field_length_must_be_at_least_1_characters:
    'La longitud del campo no debe ser inferior a 1 carácter',
  field_length_must_be_at_least_3_characters:
    'La longitud del campo no debe ser inferior a 3 caracteres',
  field_length_must_be_no_more_than_60_characters:
    'La longitud del campo no debe ser superior a 60 caracteres',
  field_length_must_be_from_1_to_30: 'La longitud del campo debe ser de 1 a 30',
  field_length_must_be_from_3_to_30: 'La longitud del campo debe ser de 3 a 30',
  field_length_must_be_from_8_to_255:
    'La longitud del campo debe ser de 8 a 255',
  field_length_must_be_36_characters:
    'La longitud del campo debe ser igual a 36 caracteres',
  formula_coefficient_should_be_used:
    'El coeficiente no se utiliza en la fórmula. Retire la relación antes de guardar.',
  'Goal have SUM type':
    'No se puede guardar la fórmula. Uno o más objetivos de la fórmula tienen un modelo de conteo Suma.',
  'Unable to delete goal. It is used by expression.':
    'No se puede eliminar el objetivo. El objetivo participa en una o más fórmulas.',
  the_partner: 'Con un socio',
  has_an_individual_condition_with_the_country:
    'ya hay una condición individual para el país Meme it',
  has_an_individual_condition_with_the_countries:
    'ya hay una condición individual para los países Meme it',
  the_link_does_not_contain_a_protocol: 'El enlace no contiene el protocolo',
  the_link_contains_only_domain: 'Especificar solo dominio',
  choose_options: 'Seleccione opciones:',
  field_length_from_2_to_30_characters:
    'Longitud de campo de 2 a 30 caracteres',
  field_length_from_2_to_50_characters:
    'Longitud de campo de 2 a 50 caracteres',
  contact_name_wrong_symbols:
    'El nombre del contacto debe incluir solo letras, números y caracteres: punto, coma, espacio, guion y subrayado',
  tag_name_wrong_symbols:
    'El nombre de la etiqueta solo puede incluir letras, números y caracteres: punto, coma, espacio, guión, subrayado, @, paréntesis y /',
  field_has_been_removed:
    'El campo ha sido borrado. Actualice la página para obtener una lista actualizada de campos.',
  the_amount_for_non_monetary_events_is_not_available:
    'La cantidad para eventos no monetarios no está disponible.',
  the_number_must_be_positive: 'El número debe ser positivo',
  you_must_choose_at_least_one_goal: 'Debe seleccionar al menos un objetivo',
  the_conversion_name_should_contains:
    'El nombre de la conversión debe contener solo letras latinas, guion y subrayado.',
  general_settings_name_wrong_symbols:
    'El título solo puede incluir letras, números y caracteres: punto, guion, espacio y subrayado',
  general_settings_email_wrong_symbols:
    'Una dirección de correo electrónico solo puede incluir letras, números y caracteres latinos: puntos, guiones bajos y @',
  image_wrong_dimensions: 'Tamaños de imagen incorrectos',
  file_wrong_size: 'Tamaño de archivo incorrecto',
  goal_the_value_from_wrong_symbols:
    'El valor debe ser un número o caracteres: "@" o "-@".',
  goal_the_value_to_wrong_symbols:
    'El valor debe ser un número o el símbolo "@"',
  'The key should contains only latin characters.':
    'La clave debe contener sólo caracteres latinos.',
  maximum_length_3_characters: 'Longitud máxima 3 caracteres',
  maximum_length_10_characters: 'Longitud máxima 10 caracteres',
  maximum_length_100_characters: 'Longitud máxima 100 caracteres',
  maximum_length_255_characters: 'Longitud máxima 255 caracteres',
  maximum_length_1000_characters: 'Longitud máxima 1000 caracteres',
  maximum_length_2000_characters: 'Longitud máxima 2000 caracteres',
  maximum_length_13_characters: 'Longitud máxima 13 caracteres',
  postback_limit: 'Se ha alcanzado el límite de 5 postbacks para este tipo',
  'GRPC response error: Postback type limit exceeded':
    'Se ha alcanzado el límite de 5 postbacks para este tipo',
  "Can't update condition count if postbacks are taken":
    'No se puede actualizar el número de condiciones si hay postbacks aceptados',
  "Can't update goals if they used in postbacks":
    'No se pueden cambiar los objetivos si se usan en postbacks',
  formula_error: 'Error de fórmula',
  only_latin_characters: 'El campo debe contener sólo letras latinas.',
  only_latin_characters_numbers_dash_and_underscore:
    'El campo debe contener únicamente letras, números, guiones y subrayados latinos.',
  "Can't delete resource": 'No se puede borrar la entrada',
  'The field is required.': '$t(validations:required)',
  currency_with_existing_code: 'La moneda con este código ya existe',
  insufficient_rights_to_perform_the_action:
    'No hay suficientes permisos para ejecutar la acción',
  allowed_formats_png_jpeg_jpg: 'Formatos permitidos: .png, .jpeg, .jpg',
  'Incorrect wallet for the specified currency code':
    'El número de billetera no coincide con la moneda seleccionada',
  "Can't update currency, it use in conditions":
    'No se puede cambiar la entrada, la moneda se utiliza en las condiciones',
  single_currency: 'No se puede desactivar la moneda única',
  select_currency: 'Seleccione la moneda',
  invoice_generation_period_required:
    'No se ha seleccionado ningún período de autogeneración.',
  invoice_generation_different_days: 'Elija dos días diferentes',
  select_conversion_currency:
    'Moneda no especificada. Por favor, indique la moneda.',
  condition_sub_id_values_length:
    'La longitud de cada valor de campo no debe exceder los 60 caracteres',
  field_length_must_be_from_1_to_250:
    'La longitud del campo debe ser de 1 a 250 caracteres',
  offer_postback_status_required: 'Seleccione al menos 1 estado de conversión',
  only_latin_cyrillic_letters_are_allowed:
    'Solo se permiten letras, números y símbolos latinos y cirílicos',
  img_wrong_format:
    'Formato de imagen no válido. $t(validations:allowed_formats_png_jpeg_jpg)',
  img_wrong_format_gif: '$t(validations:img_wrong_format), .gif',
  img_wrong_format_ico: '$t(validations:img_wrong_format), .ico',
  img_max_2_mb:
    '$t(validations:file_wrong_size). Se admiten imágenes de hasta 2 MB.',
  output_format_value_required:
    'La variable "Valor" es obligatoria en el formato de salida',
  'Unable to delete group with events':
    'No se puede eliminar el grupo de eventos',
  'Unable to delete group with goals':
    'No se puede eliminar el grupo de objetivos',
  custom_column_template_default_currency:
    'El valor y el código de moneda predeterminados solo están disponibles si se especifica Moneda o Signo de moneda',
  period_more_than_90_days:
    'El intervalo de tiempo seleccionado es superior a 90 días',
  period_more_than_180_days:
    'El intervalo de tiempo seleccionado es superior a 180 días',
  period_more_than_270_days:
    'El intervalo de tiempo seleccionado es superior a 270 días',
  'The partner is already has access to offer.':
    'El afiliado ya tiene acceso a esta oferta',
  'Promocode is already assigned.': 'El código de promoción ya está ocupado',
  'The promocodes must have at least 1 items.':
    'Debe haber al menos una entrada de código promocional en el archivo',
  'The promocodes may not have more than 1000 items.':
    'El archivo puede contener un máximo de 1000 entradas de códigos promocionales',
  'The promocode field is required.': 'No hay código de promoción',
  'The date_start field is required.':
    'El valor date_start es obligatorio para rellenar',
  'The promocode must be a string.': 'El valor promocode debe ser una cadena',
  'The promocode must be at least 3 characters.':
    'El valor promocode debe tener al menos 3 caracteres',
  'The promocode may not be greater than 30 characters.':
    'El valor de promocode no puede ser más de 30 caracteres',
  'The promocode format is invalid.': 'Formato incorrecto del valor promocode',
  'The date_start is not a valid date.':
    'Formato de fecha incorrecto date_start',
  'The date_finish is not a valid date.':
    'Formato de fecha incorrecto date_finish',
  'The date_finish must be a date after or equal to date_start.':
    'date_finish debe ser mayor o igual a date_start',
  'The limit must be an integer.': 'El valor limit debe ser un número',
  'The limit must be at least 1.': 'El valor limit debe ser mayor o igual a 1',
  'The limit must be at least 0.': 'El valor limit debe ser mayor o igual a 0',
  'The limit may not be greater than 2147483.':
    'El valor limit no puede ser mayor que 2147483',
  'The selected headers.0 is invalid.': 'Encabezado de columna no válido #1',
  'The selected headers.1 is invalid.': 'Encabezado de columna no válido #2',
  'The selected headers.2 is invalid.': 'Encabezado de columna no válido #3',
  'The selected headers.3 is invalid.': 'Encabezado de columna no válido #4',
  'The selected headers.4 is invalid.': 'Encabezado de columna no válido #5',
  'The selected headers.5 is invalid.': 'Encabezado de columna no válido #6',
  'The selected headers.6 is invalid.': 'Encabezado de columna no válido #7',
  'The selected headers.7 is invalid.': 'Encabezado de columna no válido #8',
  'The selected headers.8 is invalid.': 'Encabezado de columna no válido #9',
  'The header is invalid.': 'Título incorrecto.',
  'The data has incorrect delimiters.':
    'El archivo usa separadores incorrectos',
  'The conversion row must contain 9 items.':
    'La fila de conversión debe contener 9 columnas',
  field_is_required: 'El valor es obligatorio para rellenar',
  field_must_be_string: 'El valor debe ser una cadena',
  field_must_be_number: 'El valor debe ser un número.',
  field_must_be_uuid: 'El valor debe ser el UUID correcto.',
  'The click_id field is required.': '$t(validations:field_is_required)',
  'The click_id must be a string.': '$t(validations:field_must_be_string)',
  'The click_id must be a valid UUID.': '$t(validations:field_must_be_uuid)',
  'The tid field is required.': '$t(validations:field_is_required)',
  'The tid must be a string.': '$t(validations:field_must_be_string)',
  'The tid may not be greater than 60 characters.':
    'El valor no debe tener más de 60 caracteres',
  'The status must be a string.': '$t(validations:field_must_be_string)',
  'The income must be a number.': '$t(validations:field_must_be_number)',
  'The payout must be a number.': '$t(validations:field_must_be_number)',
  'The income field is required when income_currency is present.':
    'El valor income es obligatorio si se introduce income_currency.',
  'The income must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The income may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The income_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The income_currency field is required when income is present.':
    'El valor income_currency es obligatorio si se introduce income.',
  'The payout field is required when payout_currency is present.':
    'El valor payout es obligatorio si se rellena payout_currency.',
  'The payout must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The payout may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The payout_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The payout_currency field is required when payout is present.':
    'El valor payout_currency es obligatorio si se rellena payout.',
  'The comment_to_partner must be a string.':
    '$t(validations:field_must_be_string)',
  'The comment_to_partner may not be greater than 1000 characters.':
    '$t(validations:maximum_length_1000_characters)',
  'Unknown status': 'Estado desconocido',
  'The currency is not activated.': 'Esta moneda no está activada.',
  'The file must be a file of type: csv': 'El archivo debe tener el tipo: csv',
  'The data has incorrect csv delimiters.':
    'El archivo usa un separador csv incorrecto',
  'The file is empty.': 'Archivo vacío',
  'The promocode has another promocode type.':
    'El tipo de código promocional no se puede cambiar',
  'The promocode is personal.': 'Este código promocional es personal',
  'The admin panel url format is invalid.':
    'El formato de la URL del panel de administración no es válido',
  'The partner panel url format is invalid.':
    'El formato de la URL del panel de socios no es válido',
  'The advertiser panel url format is invalid.':
    'Formato incorrecto del URL del panel del anunciante',
  'The field must be at least 2 characters.':
    'La longitud del campo debe ser de al menos 2 caracteres.',
  'The field may not be greater than 2000 characters.':
    'La longitud del campo no debe ser superior a 2000 caracteres.',
  choose_reasons: 'Debe seleccionar las razones para ocultar',
  'The old domain format is invalid.':
    'Ingrese el dominio en el siguiente formato: https/http/example.com->example.com',
  'The domain format is invalid.':
    'Ingrese el dominio en el siguiente formato: https/http/example.com->example.com',
  'You have reached the limit of 3 private dashboards.':
    'Has alcanzado el límite de 3 paneles privados.',
  'Dashboard set for a partner cannot be private.':
    'El panel de control de afiliados no puede ser privado.',
  'The conversions must have at least 1 items.':
    'Debe haber al menos una entrada en el archivo',
  'The conversions may not have more than 1000 items.':
    'El archivo no puede contener más de 1000 entradas.',
  'The selected partner_ids is invalid.': 'Socio inactivo seleccionado',
  'Invalid two factor code': 'Código no válido',
  'Invalid two factor GA code': 'Código no válido',
  'The selected filter.goal key is invalid.': 'Clave de objetivo incorrecta',
  image_wrong_type: 'Tipo de imagen incorrecto',
  color_near_error:
    'El color no puede coincidir con el color del sector adyacente',
  customers_statistics_goal_group_required: `Obligatorio completar si se especifica el campo "$t(statistics:customers_cohort_period)"`,
  customers_statistics_cohort_period_goal_group_required: `Es necesario seleccionar un grupo de objetivos.`,
  gtm_prefix: 'El ID de Google Tag Manager debe comenzar por "GTM-"',
  product_formula_error:
    'La fórmula no debe indicar los objetivos del producto sumados en forma de números regulares, coeficientes variables o productos. Esto se debe a las peculiaridades del cálculo de las series numéricas y del cálculo de la fórmula en caso de que se modifiquen las condiciones de pago y las variables de las tasas.',
  nnco_partners: 'Para NNCO debe seleccionar uno, varios o todos los socios',
};
