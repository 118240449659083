import { call, put, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { batchActions } from 'redux-batched-actions';
import { AnyAction } from 'redux';
import i18next from 'i18next';
import uniqid from 'uniqid';
import compact from 'lodash/compact';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { fetchSaga } from './fetchSaga';
import { redirectToLogin, redirectToNotFoundPage } from './appSaga';
import { addNotification as addNotificationAction } from 'core/redux/reducers/notifications';
import { NotificationType } from 'constants/enums/NotificationType';
import { ResponseStatus } from 'constants/ResponseStatus';

import { IError } from 'models/IApiErrorDto';
import { getOfferPartnersState } from 'core/redux/selectors/offerPartners';
import {
  fetchRequests,
  setLoading,
  setRequests,
  setRequestsLoading,
  setRequestsStatus,
  approveRequest,
  rejectRequest,
} from 'core/redux/reducers/offerPartners';
import { IOfferPartnersState } from 'models/IOfferPartnersState';
import { IOptions } from 'models/IOptions';
import { PreModerationStatus } from 'constants/enums/PreModerationStatus';
import { modelPush } from 'core/redux/reducers/models';
import { IPartner } from 'models/IPartner';
import { IAction } from 'models/IAction';
import { IApiDto } from 'models/IApiDto';

function handleError(err: any) {
  return function* () {
    if (err.status === ResponseStatus.Unauthorized) {
      redirectToLogin();
    } else if (err.status === ResponseStatus.NotFound) {
      redirectToNotFoundPage();
      return;
    }

    console.error('Offer Partners Saga Error:');
    console.error(err);

    if (err.body) {
      const response = JSON.parse(err.body);

      let errors = response?.errors;
      if (!errors && response.message) {
        errors = [response];
      }
      if (errors) {
        const errorsActions: AnyAction[] = [];

        forEach(errors, (e: IError) => {
          if (e?.message) {
            errorsActions.push(
              addNotificationAction({
                id: uniqid(),
                type: NotificationType.DANGER,
                message: i18next.t(`validations:${e.message as string}`),
                timeout: 5000,
              }),
            );
          }
        });

        if (errorsActions.length > 0) {
          yield put(batchActions(compact(errorsActions)));
        }
      }
    }
  };
}

function* fetchOfferPartnersRequests({
  payload,
}: ReturnType<typeof fetchRequests>) {
  const state: IOfferPartnersState = yield select(getOfferPartnersState);
  const { offerId, endpoints } = payload;
  if (!state.isLoading && endpoints?.fetch) {
    try {
      yield put(setLoading(true));

      const options: IOptions = {
        pathMapping: {
          offer_id: offerId,
        },
      };

      const response: IApiDto = yield call(fetchSaga, endpoints.fetch, options);
      const { data, meta } = response || {};

      let datastore = undefined;
      if (data) {
        datastore = data;
      } else if (response && !isEmpty(response)) {
        datastore = response;
      }

      yield put(setRequests(datastore ?? []));
    } catch (err) {
      yield call(handleError(err));
    } finally {
      yield put(setLoading(false));
    }
  }
}

function changeRequestStatus(ids: number[], approve: boolean) {
  return function* () {
    const state: IOfferPartnersState = yield select(getOfferPartnersState);
    const { requests, offerId, endpoints, offerWidgetId } = state;
    const endpoint = approve ? endpoints?.approve : endpoints?.reject;
    if (endpoint) {
      try {
        yield put(setRequestsLoading({ ids, isLoading: true }));
        const partners: IPartner[] = [];
        forEach(ids, (id) => {
          const request = find(requests, (r) => r.id === id);
          const partner = request?.partner;
          if (!isNil(partner)) {
            partners.push(partner);
          }
        });
        const options: IOptions = {
          pathMapping: {
            offer_id: offerId,
          },
          body: {
            partner_ids: map(partners, 'id'),
          },
        };
        yield call(fetchSaga, endpoint, options);
        const status = approve
          ? PreModerationStatus.Approved
          : PreModerationStatus.Rejected;
        yield put(setRequestsStatus({ ids, status }));

        if (approve && offerWidgetId) {
          const actions: IAction[] = [];
          forEach(partners, (partner) => {
            actions.push(
              modelPush({
                modelId: offerWidgetId,
                datasource: partner as any,
                options: { path: 'partners' },
              }),
            );
          });
          if (!isEmpty(actions)) {
            yield put(batchActions(compact(actions)));
          }
        }
      } catch (err) {
        yield call(handleError(err));
      } finally {
        yield put(setRequestsLoading({ ids, isLoading: false }));
      }
    }
  };
}

function* approvePartnerRequest({
  payload: { requestIds },
}: PayloadAction<{
  requestIds: number[];
}>) {
  yield call(changeRequestStatus(requestIds, true));
}

function* rejectPartnerRequest({
  payload: { requestIds },
}: PayloadAction<{
  requestIds: number[];
}>) {
  yield call(changeRequestStatus(requestIds, false));
}

export default [
  takeEvery(fetchRequests.type, fetchOfferPartnersRequests),
  takeEvery(approveRequest.type, approvePartnerRequest),
  takeEvery(rejectRequest.type, rejectPartnerRequest),
];
