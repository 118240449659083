import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appInitialize } from 'core/redux/reducers/globals';
import { getGlobalLoading } from 'core/redux/selectors/globals';

const useApplication = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getGlobalLoading());
  const init = useCallback(() => dispatch(appInitialize()), [dispatch]);

  useEffect(() => {
    init();
  }, []);

  return {
    loading,
  };
};

export default useApplication;
