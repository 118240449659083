import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ILayoutState } from 'models/ILayoutState';
import LayoutType from 'constants/enums/LayoutType';

export const layoutInitialState: ILayoutState = {
  type: LayoutType.Laptop,
  menuIsOpen: false,
};

const layout = createSlice({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: {
    setMenuIsOpen(
      state,
      action: PayloadAction<{ menuIsOpen: boolean }>,
    ) {
      const { menuIsOpen } = action.payload;
      state.menuIsOpen = menuIsOpen;
    },
    toggleMenuIsOpen(
      state,
    ) {
      state.menuIsOpen = !state.menuIsOpen;
    },
    setLayoutType(
      state,
      action: PayloadAction<{ layoutType: LayoutType }>,
    ) {
      const { layoutType } = action.payload;
      state.type = layoutType;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = layout;
// Extract and export each action creator by name
export const {
  setMenuIsOpen,
  setLayoutType,
  toggleMenuIsOpen,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
