import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import compact from 'lodash/compact';
import join from 'lodash/join';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import uniqid from 'uniqid';
import isEmpty from 'lodash/isEmpty';
import { AnyAction } from 'redux';
import forOwn from 'lodash/forOwn';

import { fetchSaga } from './fetchSaga';
import {
  requestDict as requestDictAction,
  setDictLocalizedValue,
  setDictValue,
  setError,
  setInitialized,
  setLoading,
  setLocalizeFn,
} from 'core/redux/reducers/dicts';
import { IAction } from 'models/IAction';
import { IApiError, IError } from 'models/IApiErrorDto';
import { addNotification as addNotificationAction } from 'core/redux/reducers/notifications';
import { NotificationType } from 'constants/enums/NotificationType';
import { getDict, getDicts } from 'core/redux/selectors/dicts';
import { IDictState } from 'models/IDictState';
import { Dicts } from 'constants/enums/Dicts';
import schedulerApi from 'utils/SchedulerApi';
import { IReduxState } from 'models/IReduxState';
import { IApiDto } from 'models/IApiDto';
import { changeLang } from 'core/redux/reducers/globals';

function* requestDict({ payload }: IAction) {
  const { name, dataProvider, options, localizeFn, processingFns } = payload;

  let dictState: IDictState = yield select(getDict(name));
  try {
    if (!dictState?.loading) {
      yield (yield schedulerApi.blockingTask(function changeLoadingStatus() {
        return put(setLoading({ name, loading: true }));
      })) as PutEffect;
      if (localizeFn) {
        yield put(setLocalizeFn({ name, localizeFn }));
      }

      const response: IApiDto = yield (yield schedulerApi.backgroundTask(
        function fetchDict() {
          return call(fetchSaga, dataProvider, options);
        },
      )) as CallEffect;

      const { data } = response || {};
      let processedData: Array<unknown> = data;
      if (!isEmpty(processingFns)) {
        processedData = yield schedulerApi.backgroundTask(function process() {
          return processDict(processedData, processingFns);
        });
      }
      schedulerApi.yield();
      yield (yield schedulerApi.backgroundTask(function setValue() {
        return put(setDictValue({ name, value: processedData || [] }));
      })) as PutEffect;

      dictState = yield select(getDict(name));
      const localizeAction = localizeDict(dictState, name);
      if (localizeAction) {
        yield put(localizeAction);
      }
    }
  } catch (err: any) {
    console.error('Dict Saga Error:');
    console.error(err);

    const apiError = (err.json as IApiError) || {};
    const errors: IError[] = apiError?.errors || [];

    if (errors && !isEmpty(errors)) {
      const error = join(map(errors, 'message'), ', ');
      yield put(setError({ name, error }));

      const errorsActions: AnyAction[] = [];
      forEach(errors, (e: IError) => {
        if (e?.message && !e.source) {
          errorsActions.push(
            addNotificationAction({
              id: uniqid(),
              type: NotificationType.DANGER,
              message: e.message as string,
              timeout: 5000,
            }),
          );
        }
      });

      if (errorsActions.length > 0) {
        yield put(batchActions(compact(errorsActions)));
      }
    }
  } finally {
    const actions = [
      setInitialized({ name, initialized: true }),
      setLoading({ name, loading: false }),
    ];
    yield put(batchActions(compact(actions)));
  }
}

function processDict(dict: any, processFns?: Array<(data: any) => any>): any {
  if (processFns) {
    let processedDict = dict;
    forEach(processFns, (fn, index) => {
      try {
        processedDict = fn(processedDict);
      } catch (err) {
        console.error(`Dict Saga Error, process function #${index}:`);
        console.error(err);
      }
    });
    return processedDict;
  }
  return dict;
}

function localizeDict(dict: IDictState, name: Dicts): AnyAction | undefined {
  if (dict.localizeFn && dict.value && !isEmpty(dict.value)) {
    return setDictLocalizedValue({
      name,
      localizedValue: map(dict.value, dict.localizeFn),
    });
  }
}

function* localizeAllDicts() {
  const dictsState: IReduxState['dicts'] = yield select(getDicts);
  if (dictsState) {
    const localizedValuesActions: AnyAction[] = [];
    forOwn(dictsState, (dict: IDictState, key) => {
      const localizeAction = localizeDict(dict, key as Dicts);
      if (localizeAction) {
        localizedValuesActions.push(localizeAction);
      }
    });

    if (localizedValuesActions.length > 0) {
      yield put(batchActions(compact(localizedValuesActions)));
    }
  }
}

export default [
  takeEvery(requestDictAction.type, requestDict),
  takeEvery(changeLang.type, localizeAllDicts),
];
