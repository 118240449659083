import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toNumber from 'lodash/toNumber';
import mapKeys from 'lodash/mapKeys';
import assign from 'lodash/assign';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import map from 'lodash/map';

import ILandingsData from 'models/ILandingsData';
import { Constants } from 'constants/Constants';
import { ILanding } from 'models/ILanding';

const initialState: ILandingsData = {
  products: {},
  offers: {},
};

const landings = createSlice({
  name: 'landings',
  initialState,
  reducers: {
    setLandings: (state, action: PayloadAction<ILandingsData>) => {
      state.products = action.payload.products;
      state.offers = action.payload.offers;
    },
    updateLandings: (state, action: PayloadAction<ILandingsData>) => {
      state.products = assign({}, state.products, action.payload?.products);
      state.offers = assign({}, state.offers, action.payload?.offers);
      state.allDataLoadTime =
        action.payload?.allDataLoadTime ?? state.allDataLoadTime;
    },
    updateLanding: (state, action: PayloadAction<ILanding>) => {
      const landing = action.payload;
      if (landing.offer_id) {
        const data = state.offers[landing.offer_id]?.data;
        if (data) {
          state.offers[landing.offer_id].data = map(data, (d) => {
            if (d.id === landing.id) {
              return landing;
            }
            return d;
          });
        }
      } else if (landing.product_id) {
        const data = state.products[landing.product_id]?.data;
        if (data) {
          state.products[landing.product_id].data = map(data, (d) => {
            if (d.id === landing.id) {
              return landing;
            }
            return d;
          });
        }
      }
    },
    deleteLandings: (
      state,
      action: PayloadAction<{
        products: number[];
        offers: number[];
      }>,
    ) => {
      const { products, offers } = action.payload;
      state.products = omit(state.products, products);
      state.offers = omit(state.offers, offers);
    },
    deleteLanding: (state, action: PayloadAction<ILanding>) => {
      const landing = action.payload;
      if (landing.offer_id) {
        const data = state.offers[landing.offer_id]?.data;
        if (data) {
          state.offers[landing.offer_id].data = filter(data, (d) => {
            return d.id !== landing.id;
          });
        }
      } else if (landing.product_id) {
        const data = state.products[landing.product_id]?.data;
        if (data) {
          state.products[landing.product_id].data = filter(data, (d) => {
            return d.id !== landing.id;
          });
        }
      }
    },
    deleteExpiredLandings: (state) => {
      const products: ILandingsData['products'] = {};
      const offers: ILandingsData['offers'] = {};
      const currentTime = new Date().getTime();
      const allDataLoadTime = state.allDataLoadTime;
      const allDataIsExpired =
        !isNil(allDataLoadTime) &&
        currentTime - allDataLoadTime > Constants.LandingsCacheTime;
      if (!allDataIsExpired) {
        mapKeys(state.products, (value, key) => {
          const isExpired =
            currentTime - value.loadTime > Constants.LandingsCacheTime;
          if (!isExpired) {
            products[toNumber(key)] = value;
          }
        });
        mapKeys(state.offers, (value, key) => {
          const isExpired =
            currentTime - value.loadTime > Constants.LandingsCacheTime;
          if (!isExpired) {
            offers[toNumber(key)] = value;
          }
        });
      }
      state.products = products;
      state.offers = offers;
      state.lastCleaningTime = currentTime;
      state.allDataLoadTime = allDataIsExpired ? undefined : allDataLoadTime;
    },
    deleteAllLandings: (state) => {
      state.products = {};
      state.offers = {};
      state.lastCleaningTime = new Date().getTime();
    },
  },
});

export const { actions, reducer } = landings;
export const {
  setLandings,
  updateLandings,
  updateLanding,
  deleteLandings,
  deleteLanding,
  deleteExpiredLandings,
  deleteAllLandings,
} = actions;
export default reducer;
