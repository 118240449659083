import { IGlobals } from 'models/IGlobals';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDataProvider } from 'models/IDataProvider';
import { IOptions } from 'models/IOptions';

const initialState: IGlobals = {
  title: 'Alanbase',
  dict: {},
  loading: false,
};

const globals = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    appInitialize() {},
    appLogout() {},
    callFetch(
      state,
      action: PayloadAction<{
        modelId: string;
        dataProvider: IDataProvider;
        options: IOptions;
      }>,
    ) {},
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setDict(state, action: PayloadAction<Record<string, Record<string, any>>>) {
      state.dict = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    changeLang(state, action: PayloadAction<string>) {
      state.lang = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = globals;
// Extract and export each action creator by name
export const {
  setTitle,
  setLoading,
  setDict,
  changeLang,
  appInitialize,
  appLogout,
  callFetch,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
