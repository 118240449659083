import React from 'react';

import useApplication from './useApplication';
import Loading from 'components/Loading';
import ModuleLoader from 'core/components/Router/ModuleLoader';
import { getClientId } from 'utils/storageUtils';
import PathContextProvider from 'routes/PathContext';

function Application(): React.JSX.Element {
  const { loading } = useApplication();
  const clientId = getClientId();

  return (
    <>
      <PathContextProvider>
        <ModuleLoader clientId={clientId} />
        {loading && <Loading fullScreen />}
      </PathContextProvider>
    </>
  );
}

export default Application;
