import React, { useRef, useState } from 'react';
import { isTestEnvironment } from 'utils/commonUtils';

type LayoutProviderType = {
  layoutRef: React.RefObject<HTMLDivElement>;
  isAdaptivePage: boolean;
  setIsAdaptivePage: (value: boolean) => void;
};

export const LayoutContext = React.createContext<Partial<LayoutProviderType>>(
  {},
);

function LayoutProvider({ children }: { children: React.ReactNode }) {
  const layoutRef = useRef<HTMLDivElement>(null);
  const [isAdaptivePage, setIsAdaptivePage] = useState<boolean>(false);

  return (
    <LayoutContext.Provider
      value={{
        layoutRef,
        isAdaptivePage: isAdaptivePage,
        setIsAdaptivePage,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutProvider;
