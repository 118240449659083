export default {
  yes: 'Sí',
  no: 'No',
  delete: 'Eliminar',
  log_in: 'Entrar',
  'log out': 'Salir',
  'my profile': 'Mi perfil',
  offer: 'Oferta',
  product: 'Producto',
  active: 'Activo',
  update: 'Actualizar',
  replace: 'Reemplazar',
  inactive: 'Inactivo',
  cancel: 'Cancelación',
  to_cancel: 'Cancelación',
  duplicate: 'Duplicar',
  partners: 'Socios',
  dashboard: 'Tablero de instrumentos',
  'oops something went wrong here': '¡Ups! Algo salió mal',
  'do you want to log out of your profile': '¿Quieres salir de tu perfil?',
  'save changes': 'Guardar cambios',
  save: 'Guardar',
  slug: 'Slug',
  settings: 'Ajustes',
  create: 'Crear',
  add: 'Añadir',
  'an error has occurred': 'Se ha producido un error',
  enabled: 'Activo',
  disabled: 'Inactivo',
  error: 'Error',
  access_denied: 'Acceso prohibido',
  continue: 'Continuar',
  apply: 'Aplicar',
  apply_changes: 'Aplicar cambios',
  'removal was successful': 'Eliminación exitosa',
  cancellation_successful: 'Cancelación exitosa',
  copy: 'Copiar',
  copy_success: 'Texto copiado correctamente',
  copy_success_any: 'Copiado correctamente',
  open: 'Abrir',
  min: 'min',
  max: 'max',
  edit_profile: 'Editar perfil',
  maximum: 'Máximo',
  edit: 'Cambiar',
  edit2: 'Editar',
  date: 'Fecha',
  id: 'ID',
  email: 'Email',
  status: 'Estado',
  count: 'Cantidad',
  count_short: 'Cantidad',
  amount: 'Cantidad',
  total: 'Total',
  supported_file_type: 'Tipo de archivo soportado',
  image_dimensions: 'Dimensiones máximas: {{width}}*{{height}} (ancho*altura)',
  image_min_dimensions:
    'Dimensiones mínimas: {{width}}*{{height}} (ancho*altura)',
  image_size: 'Tamaño máximo de imagen: {{size}}',
  image_uploading_error: 'Error al cargar la imagen',
  role: 'El papel',
  manager: 'Gerente',
  on_moderation: 'En la moderación',
  date_or_period: 'Fecha o período',
  main_page: 'Inicio',
  products_list: 'Lista de productos',
  offers_list: 'Lista de ofertas',
  admins_list: 'Lista de administradores',
  partners_list: 'Lista de socios',
  advertisers_list: 'Lista de anunciantes',
  paste_user_name: '{{user_name}}',
  paste_user_email: '{{user_email}}',
  paste_name: '{{name}}',
  paste_news_name: '{{news_name}}',
  paste_offer_name: '{{offer_name}}',
  paste_product_name: '{{product_name}}',
  product_new: 'Nuevo producto',
  product_editing: 'Editar {{product_name}}',
  offer_new: 'Nueva oferta',
  offer_editing: 'Editar {{offer_name}}',
  offer_conditions_editing: 'Editar condiciones',
  customers: 'Clientes',
  statistics: 'Estadísticas',
  statistics_common: 'Estadísticas básicas',
  statistics_event: 'Estadísticas de eventos',
  statistics_conversion: 'Estadísticas de conversiones',
  statistics_qualification: 'Estadísticas sobre calificaciones',
  statistics_cohort: 'Análisis de cohortes',
  paste_statistics_name: '{{statistics_name}}',
  withdrawal_requests: 'Solicitudes de retiro de fondos',
  invoices: 'Cuentas',
  common_settings: 'Configuración básica',
  finance: 'Finanzas',
  requests_for_access: 'Solicitudes de acceso',
  approve: 'Aceptar',
  reject: 'Rechazar',
  referrals: 'Referencias',
  currency: 'Moneda',
  actions: 'Acciones',
  close: 'Cerrar',
  per_1000: 'por 1000',
  no_data: 'Sin datos',
  confirmation: 'Confirmación',
  accept: 'Aceptar',
  accepted: 'Aceptado',
  declined: 'Rechazado',
  ok: 'OK',
  logs: 'Logis',
  reload: 'Actualizar',
  load_more: 'Descargar más',
  export: 'Exportación',
  default: 'Por defecto',
  set_default: 'Usar por defecto',
  click: 'Clic',
  confirm: 'Confirmar',
  promo: 'Materiales promocionales',
  promo_codes: 'Códigos promocionales',
  download: 'Descargar',
  add_new_group: 'Añadir nuevo grupo',
  and: 'y',
  to_export: 'Exportar',
  to_import: 'Importar',
  terms_of_use: 'Condiciones de uso',
  privacy_policy: 'Política de privacidad',
  tags: 'Etiquetas',
  news: 'Noticias',
  add_news: 'Añadir noticias',
  edit_news: 'Editar noticias',
  info: 'Información',
};
