import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import defaultTo from 'lodash/defaultTo';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import assign from 'lodash/assign';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { IAction } from 'models/IAction';
import { IApiDto } from 'models/IApiDto';

const initialState: Record<string, any> = {};

export type ModelChangeOptionsType = {
  modelFieldId?: string;
  path?: string;
  modelId?: string | number | null;
  place?: 'top' | 'bottom';
};

type ModelChangeType = {
  modelId: string;
  datasource: IApiDto | { [key: string]: any } | undefined;
  options?: ModelChangeOptionsType;
};

function updateArrayModel(state: any[] | null, { payload }: IAction) {
  return map(state, (item: any) => {
    const modelFieldId = get(payload, 'options.modelFieldId', 'id');

    const isSameItem = (data: any): boolean => {
      return (
        get(item, modelFieldId) === get(data, modelFieldId) ||
        get(item, modelFieldId) === get(payload, 'options.modelId')
      );
    };

    if (isArray(payload.datasource) && !isEmpty(payload.datasource)) {
      for (let i = 0; i < payload.datasource.length; i++) {
        const datasourceElement = payload.datasource[i];
        if (isSameItem(datasourceElement)) {
          return assign({}, item, datasourceElement);
        }
      }
    } else if (isSameItem(payload.datasource)) {
      return assign({}, item, payload.datasource);
    }
    return item;
  });
}

function updateObjectModel(state = null, { payload }: IAction) {
  const preparedDatasource = isArray(payload.datasource)
    ? payload.datasource
    : pickBy(payload.datasource, (v: any) => !isNil(v));
  let valueToAssign = {};

  if (payload.options?.path) {
    set(valueToAssign, payload.options.path, preparedDatasource);
  } else {
    valueToAssign = preparedDatasource;
  }

  return assign({}, state, valueToAssign);
}

const models = createSlice({
  name: 'models',
  initialState,
  reducers: {
    setModel(state, action: PayloadAction<ModelChangeType>) {
      const modelId = action.payload.modelId;
      state[modelId] = action.payload.datasource;
    },
    modelPush(state, action: PayloadAction<ModelChangeType>) {
      const modelId = action.payload.modelId;
      let modelState = state[modelId];
      const options: ModelChangeOptionsType | undefined =
        action.payload.options;
      const path = options?.path;
      const addDatasource = (oldState?: Array<unknown> | null) => {
        if (isArray(action.payload.datasource)) {
          return options?.place === 'top'
            ? [...action.payload.datasource, ...(oldState || [])]
            : [...(oldState || []), ...action.payload.datasource];
        }
        return options?.place === 'top'
          ? [action.payload.datasource, ...(oldState || [])]
          : [...(oldState || []), action.payload.datasource];
      };
      if (path) {
        set(modelState, path, addDatasource(get(modelState, path, [])));
      } else {
        modelState = addDatasource(modelState);
      }

      state[modelId] = modelState;
    },
    modelDelete(state, action: PayloadAction<ModelChangeType>) {
      const modelId = action.payload.modelId;
      let modelState = state[modelId];
      const options: ModelChangeOptionsType | undefined =
        action.payload.options;
      const path = options?.path;
      const modelFieldId = defaultTo(options?.modelFieldId, 'id');
      const filterFn = (item: any) => {
        if (options?.modelId) {
          return item[modelFieldId] !== options.modelId;
        } else {
          return (
            item[modelFieldId] !==
            (action.payload.datasource as any)?.[modelFieldId]
          );
        }
      };

      if (path) {
        set(modelState, path, filter(get(modelState, path, []), filterFn));
      } else {
        const stateToFilter = defaultTo(
          path ? get(modelState, path) : modelState,
          [],
        );
        modelState = filter(stateToFilter, filterFn);
      }

      state[modelId] = modelState;
    },
    modelUpdate(state, action: PayloadAction<ModelChangeType>) {
      const modelId = action.payload.modelId;
      const modelState = state[modelId];
      const options: ModelChangeOptionsType | undefined =
        action.payload.options;
      const path = options?.path;
      let newState = undefined;
      let changingState = modelState;
      if (path) {
        changingState = get(modelState, path);
      }
      if (isArray(changingState)) {
        newState = updateArrayModel(changingState, action);
      } else {
        newState = updateObjectModel(changingState, action);
      }

      if (path) {
        const stateCopy = cloneDeep(modelState);
        set(stateCopy, path, newState);
        newState = stateCopy;
      }

      state[modelId] = newState;
    },
  },
});

export const { actions, reducer } = models;
export const { setModel, modelPush, modelUpdate, modelDelete } = actions;
export default reducer;
