import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import useDict from 'core/hooks/useDict';
import { Dicts } from 'constants/enums/Dicts';

const GoogleTagManager: React.FC = (): JSX.Element => {
  const { value: settings } = useDict(Dicts.GeneralSettings);
  const gtmId = settings?.gtm_id;
  const isGtmEnabled = settings?.is_gtm_enabled && !isEmpty(gtmId);

  // init Google Tag Manager
  useEffect(() => {
    if (isGtmEnabled && !(window as any)['dataLayer']) {
      const gtmInit = function (w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      };
      gtmInit(window, document, 'script', 'dataLayer', gtmId);
    }
  }, [isGtmEnabled, gtmId]);

  if (!isGtmEnabled) return <React.Fragment />;

  return (
    <noscript id="gtm-noscript" key="gtm-noscript">
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManager;
