export default {
  'maximum length of a role name is no more than 255 characters':
    'Maximum length of a role name is no more than 255 characters',
  'enter role name': 'Enter role name',
  required: 'Required',
  'field length from 2 to 200 characters':
    'Field length from 2 to 200 characters',
  'field length from 2 to 3000 characters':
    'Field length from 2 to 3000 characters',
  'field length from 3 to 60 characters':
    'Field length from 3 to 60 characters',
  'field length from 1 to 40 characters':
    'Field length from 1 to 40 characters',
  'field length from 1 to 50 characters':
    'Field length from 1 to 50 characters',
  'field length from 1 to 60 characters':
    'Field length from 1 to 60 characters',
  'field length from 3 to 120 characters':
    'Field length from 3 to 120 characters',
  'field length no more than 200 characters':
    'Field length no more than 200 characters',
  'field length no more than 255 characters':
    'Field length no more than 255 characters',
  'field length no more than 120 characters':
    'Field length no more than 120 characters',
  'field length no more than 150 characters':
    'Field length no more than 150 characters',
  'invalid email address': 'Invalid email address',
  'The email has already been taken.': 'The email has already been taken',
  'The email has already been taken': 'The email has already been taken',
  'The name must be at least 3 characters':
    'The name must be at least 3 characters',
  'The value must be a link': 'The value must be a link',
  'The name must be no more than 30 characters':
    'The name must be no more than 30 characters',
  'The name must be no more than 60 characters':
    'The name must be no more than 60 characters',
  'The conversion name must be no more than 30 characters':
    'The conversion name must be no more than 30 characters',
  'The click lifetime must be from 1 to 1095':
    'The click lifetime must be from 1 to 1095',
  'The minimum password length must be at least 8 characters':
    'The minimum password length must be at least 8 characters',
  'The maximum password length must be no more than 255 characters':
    'The maximum password length must be no more than 255 characters',
  'passwords must match': 'Password must match',
  'Old password is incorrect': 'Old password is incorrect',
  'The ID must be at least 3 characters':
    'The ID must be at least 3 characters',
  'The ID must be no more than 30 characters':
    'The ID must be no more than 30 characters',
  'The field can contain only latin characters, dot(.), dash(-) and underscore(_)':
    'The field can contain only latin characters, dot(.), dash(-) and underscore(_)',
  'The key has already been taken.': 'The key has already been taken',
  'The rules field must have a value.': 'Choose access rights for the role',
  maximum_5_managers_can_be_assigned: 'A maximum of 5 managers can be assigned',
  the_URL_must_be_at_least_3_characters:
    'The URL must be at least 3 characters',
  the_URL_must_be_no_more_than_255_characters:
    'The URL must be no more than 255 characters',
  the_target_link_must_be_at_least_3_characters:
    'The target link must be at least 3 characters',
  the_target_link_must_be_no_more_than_2000_characters:
    'The target link must be no more than 2000 characters',
  the_link_must_be_no_more_than_2000_characters:
    'The link must be no more than 2000 characters',
  field_length_must_be_no_more_than_2000_characters:
    'The field length must be no more than 2000 characters',
  the_number_must_be_integer: 'The number must be integer',
  the_number_must_be_at_least_1000000:
    'The number must be no more than\n1 000 000',
  the_number_must_be_at_least_10: 'The number must be at least 10',
  the_number_must_be_from_10_to_1000000:
    'The number must be from 10 to 1 000 000',
  the_number_must_be_from_1_to_1000000:
    'The number must be from 1 to 1 000 000',
  the_number_must_be_from_0_to_100: 'The number must be from 0 to 100',
  tracking_domain_wrong_format: 'Wrong format of the domain name',
  tracking_domain_without_click:
    'You entered an incorrect value in the field. Please provide a link without the prefix "click"',
  hold_period_must_be_from_0_to_60: 'Hold period can be from 0 to 60',
  postclick_must_be_from_1_to_1825: 'Postclick can be from 1 to 1825 (5 years)',
  the_field_must_be_filled_in_or_the_flag_is_unlimited:
    'The field must be filled in or the flag is unlimited',
  the_field_must_be_filled_in_or_the_flag_is_use_hold_period:
    'The field must be filled in or the flag is not set for the hold period',
  only_Latin_letters_are_allowed:
    'Only Latin letters, numbers and symbols are allowed',
  ID_must_be_unique_within_the_offer: 'ID must be unique within the offer',
  countries: 'Countries: ',
  used_in_other_conditions: 'used in other conditions',
  target_link_wrong_params: 'Macros written incorrectly',
  at_least_one_goal_must_be_added: 'At least one goal must be added',
  at_least_one_country_must_be_added: 'At least one country must be added.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_event:
    'It is impossible to calculate the percentage for payout for a non-monetary event.',
  you_cannot_set_a_SUM_counting_model_for_a_single_goal_with_a_non_monetary_event:
    'You cannot set a SUM counting model for a single goal with a non-monetary event.',
  you_cannot_set_multiple_payout_because_sum_parameter_can_be_reachable_only_once:
    'You cannot set Multiple payout, because sum parameter can be reachable only once.',
  it_is_impossible_to_calculate_the_percentage_of_payout_for_sum_counting_model:
    'It is impossible to calculate the percentage of payout for SUM counting model.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_non_monetary_events:
    'It is impossible to calculate the percentage for payout for a non-monetary events.',
  it_is_impossible_to_payout_of_a_fixed_amount_several_times_for_non_recurring_non_monetary_events:
    'It is impossible to payout of a fixed amount several times for non-recurring non-monetary events.',
  it_is_impossible_to_calculate_the_percentage_for_payout_for_a_multiple_goals_with_monetary_events:
    'It is impossible to calculate the percentage for payout for a multiple goals with monetary events.',
  it_is_impossible_to_payout_several_times_because_multiple_goals_can_only_work_once:
    'It is impossible to payout several times, because multiple goals can only work once.',
  you_cannot_set_a_SUM_counting_model_for_a_multiple_goals_with_a_non_monetary_events:
    'You cannot set a SUM counting model for a multiple goals with a non-monetary events.',
  the_value_cannot_be_more_than_2_147_483_647:
    'The value cannot be more than 2 147 483 647',
  the_value_cannot_be_more_than_2_147_483:
    'The value cannot be more than 2 147 483',
  'the_value_cannot_be_less_than_-2_147_483':
    'The value cannot be less than -2 147 483',
  the_end_value_must_be_greater_than_or_equal_to_the_start_value:
    'The end value must be greater than or equal to the start value',
  email_length_must_be_no_more_than_255_characters:
    'Email length must be no more than 255 characters',
  the_value_must_be_at_least_1: 'The value must be at least 1',
  the_value_must_be_at_least_2147483647:
    'The value must be at least -2147483647',
  the_value_must_be_no_more_than_2147483647:
    'The value must be no more than 2147483647',
  the_name_must_be_no_more_than_128_characters:
    'The name must be no more than 128 characters',
  value_of_the_field_must_be_no_more_than_128_characters:
    'Value of the field must be no more than 128 characters',
  the_value_must_be_no_more_than: 'The value must be no more than',
  category_name_must_be_from_3_to_255:
    "The category name's length must be from 3 to 255",
  category_name_wrong_symbols:
    'The category name should include only: letters, digits, dots, commas, space and dashes',
  event_group_name_must_be_from_3_to_60:
    "The event group name's length must be from 3 to 60",
  event_group_name_wrong_symbols:
    'The event group name should include only: letters, digits, underline, space and dashes',
  goal_group_name_must_be_from_3_to_60:
    "The goal group name's length must be from 3 to 60",
  goal_group_key_must_be_from_1_to_60:
    "The goal group key's length must be from 1 to 60",
  field_must_be_from_1_to_60: 'Length of the field must be from 1 to 60',
  goal_group_name_wrong_symbols:
    'The goal group name should include only: letters, digits, underline, space and dashes',
  goal_group_key_wrong_symbols:
    'The goal group key should include only: latin letters, digits, underline and dashes',
  field_value_wrong_symbols:
    'The field value should include only: letters, digits, underline and dashes',
  ips_required: 'Fill in the IP address list',
  ips_wrong: 'Some IP addresses are incorrect',
  ips_limit: 'You have reached the limit of 100 IP address',
  type_string: 'The field value must be a string',
  type_number: 'The field value must be a number',
  the_field_value_must_be_greater_than_zero:
    'The field value must be greater than 0',
  the_field_value_must_be_greater_than_or_equal_to_zero:
    'The field value must be greater than or equal to 0',
  'The comment must be a string.': 'The comment must be a string.',
  'The comment must be at least 1 characters.':
    'The comment must be at least 1 characters.',
  'The comment may not be greater than 255 characters.':
    'The comment may not be greater than 255 characters.',
  field_length_must_be_at_least_1_character:
    'Field length must be at least 1 character',
  field_length_must_be_at_least_1_characters:
    'Field length must be at least 1 characters',
  field_length_must_be_at_least_3_characters:
    'Field length must be at least 3 characters',
  field_length_must_be_no_more_than_60_characters:
    'Field length must be no more than 60 characters',
  field_length_must_be_from_1_to_30: 'Field length must be from 1 to 30',
  field_length_must_be_from_3_to_30: 'Field length must be from 3 to 30',
  field_length_must_be_from_8_to_255: 'Field length must be from 8 to 255',
  field_length_must_be_36_characters: 'Field length must be 36 characters',
  formula_coefficient_should_be_used:
    'The coefficient is not used in the formula. Delete the coefficient before saving.',
  'Goal have SUM type':
    'The formula cannot be saved. One or more goals in the formula have the calculation model Sum.',
  'Unable to delete goal. It is used by expression.':
    'Unable to delete goal. It is used by expression.',
  the_partner: 'The partner',
  has_an_individual_condition_with_the_country:
    'has an individual condition with the country',
  has_an_individual_condition_with_the_countries:
    'has an individual condition with the countries',
  the_link_does_not_contain_a_protocol: 'The link does not contain a protocol',
  the_link_contains_only_domain: 'Specify domain only',
  choose_options: 'Select options:',
  field_length_from_2_to_30_characters: 'Field length from 2 to 30 characters',
  field_length_from_2_to_50_characters: 'Field length from 2 to 50 characters',
  contact_name_wrong_symbols:
    'The name of contact should include only letters, digits and symbols: dot, comma, dash, space and underscore',
  tag_name_wrong_symbols:
    'The name of tag should include only letters, digits and symbols: dot, comma, dash, space, underscore, @, parentheses and /',
  field_has_been_removed:
    'The field has been removed. Update page to get a actual list of fields.',
  the_amount_for_non_monetary_events_is_not_available:
    'The "SUM" for non-monetary events is not available.',
  the_number_must_be_positive: 'The number must be positive',
  you_must_choose_at_least_one_goal: 'You must choose at least one goal',
  the_conversion_name_should_contains:
    'The conversion name should contains only latin characters, dash and underscore.',
  general_settings_name_wrong_symbols:
    'The name should include only letters, digits and symbols: dot, dash, space and underscore',
  general_settings_email_wrong_symbols:
    'The email should include only latin letters, digits and symbols:  dot, dash, underscore and @',
  image_wrong_dimensions: 'Image dimensions are not correct',
  file_wrong_size: 'File size is not correct',
  goal_the_value_from_wrong_symbols:
    'The value must be a number or "@", "-@" symbols.',
  goal_the_value_to_wrong_symbols: 'The value must be a number or "@" symbol.',
  'The key should contains only latin characters.':
    'The key should contains only latin characters.',
  maximum_length_3_characters: 'Maximum length 3 characters',
  maximum_length_10_characters: 'Maximum length 10 characters',
  maximum_length_100_characters: 'Maximum length 100 characters',
  maximum_length_255_characters: 'Maximum length 255 characters',
  maximum_length_1000_characters: 'Maximum length 1000 characters',
  maximum_length_2000_characters: 'Maximum length 2000 characters',
  maximum_length_13_characters: 'Maximum length 13 characters',
  postback_limit: 'The limit of 5 postbacks for this type has been reached',
  'GRPC response error: Postback type limit exceeded':
    'The limit of 5 postbacks for this type has been reached',
  "Can't update condition count if postbacks are taken":
    "Can't update condition count if postbacks are taken",
  "Can't update goals if they used in postbacks":
    "Can't update goals if they used in postbacks",
  formula_error: 'Formula error',
  only_latin_characters: 'The field should contains only latin characters.',
  only_latin_characters_numbers_dash_and_underscore:
    'The field should contains only latin characters, numbers, dash and underscore.',
  "Can't delete resource": "Can't delete resource",
  'The field is required.': '$t(validations:required)',
  currency_with_existing_code: 'Currency with this code already exists',
  insufficient_rights_to_perform_the_action:
    'Insufficient rights to perform the action',
  allowed_formats_png_jpeg_jpg: 'Allowed formats: .png, .jpeg, .jpg',
  'Incorrect wallet for the specified currency code':
    'Incorrect wallet for the specified currency code',
  "Can't update currency, it use in conditions":
    "Can't update currency, it use in conditions",
  single_currency: "Can't disable single currency",
  select_currency: 'Select currency',
  invoice_generation_period_required: 'No period selected for auto-generation.',
  invoice_generation_different_days: 'Select two different days',
  select_conversion_currency:
    'Currency is not defined. Please, set the currency.',
  condition_sub_id_values_length:
    'Length of each value of the field can not be more than 60 symbols',
  field_length_must_be_from_1_to_250: 'The field length must be from 1 to 250',
  offer_postback_status_required: 'Select at least 1 conversion status',
  only_latin_cyrillic_letters_are_allowed:
    'The field should contains only latin and cyrillic characters, numbers',
  img_wrong_format:
    'Wrong format of the image. $t(validations:allowed_formats_png_jpeg_jpg)',
  img_wrong_format_gif: '$t(validations:img_wrong_format), .gif',
  img_wrong_format_ico: '$t(validations:img_wrong_format), .ico',
  img_max_2_mb:
    '$t(validations:file_wrong_size). Images up to 2 MB are allowed.',
  output_format_value_required: '"Value" variable required in output format',
  'Unable to delete group with events': 'Unable to delete group with events',
  'Unable to delete group with goals': 'Unable to delete group with goals',
  custom_column_template_default_currency:
    'Value and code in default currency available if Currency or Currency Symbol is set only.',
  period_more_than_90_days:
    'The selected time interval is greater than 90 days',
  period_more_than_180_days:
    'The selected time interval is greater than 180 days',
  period_more_than_270_days:
    'The selected time interval is greater than 270 days',
  'The partner is already has access to offer.':
    'The partner is already has access to offer.',
  'Promocode is already assigned.': 'Promo code is already assigned',
  'The promocodes must have at least 1 items.':
    'The promo codes must have at least 1 items',
  'The promocodes may not have more than 1000 items.':
    'The promo codes may not have more than 1000 items',
  'The promocode field is required.': 'The promocode field is required',
  'The date_start field is required.': 'The date_start field is required',
  'The promocode must be a string.': 'The promo code must be a string',
  'The promocode must be at least 3 characters.':
    'The promo code must be at least 3 characters',
  'The promocode may not be greater than 30 characters.':
    'The promo code may not be greater than 30 characters',
  'The promocode format is invalid.': 'The promocode value format is invalid',
  'The date_start is not a valid date.': 'The date_start is not a valid date',
  'The date_finish is not a valid date.': 'The date_finish is not a valid date',
  'The date_finish must be a date after or equal to date_start.':
    'The date_finish must be a date after or equal to date_start',
  'The limit must be an integer.': 'The limit must be an integer',
  'The limit must be at least 1.': 'The limit must be at least 1',
  'The limit must be at least 0.': 'The limit must be at least 0',
  'The limit may not be greater than 2147483.':
    'The limit may not be greater than 2147483',
  'The selected headers.0 is invalid.': 'Header of column #1 is invalid',
  'The selected headers.1 is invalid.': 'Header of column #2 is invalid',
  'The selected headers.2 is invalid.': 'Header of column #3 is invalid',
  'The selected headers.3 is invalid.': 'Header of column #4 is invalid',
  'The selected headers.4 is invalid.': 'Header of column #5 is invalid',
  'The selected headers.5 is invalid.': 'Header of column #6 is invalid',
  'The selected headers.6 is invalid.': 'Header of column #7 is invalid',
  'The selected headers.7 is invalid.': 'Header of column #8 is invalid',
  'The selected headers.8 is invalid.': 'Header of column #9 is invalid',
  'The header is invalid.': 'The header is invalid.',
  'The data has incorrect delimiters.': 'The data has incorrect delimiters.',
  'The conversion row must contain 9 items.':
    'The conversion row must contain 9 items.',
  field_is_required: 'The field is required.',
  field_must_be_string: 'The field must be a string.',
  field_must_be_number: 'The field must be a number.',
  field_must_be_uuid: 'The field must be a valid UUID.',
  'The click_id field is required.': '$t(validations:field_is_required)',
  'The click_id must be a string.': '$t(validations:field_must_be_string)',
  'The click_id must be a valid UUID.': '$t(validations:field_must_be_uuid)',
  'The tid field is required.': '$t(validations:field_is_required)',
  'The tid must be a string.': '$t(validations:field_must_be_string)',
  'The tid may not be greater than 60 characters.':
    'The tid may not be greater than 60 characters.',
  'The status must be a string.': '$t(validations:field_must_be_string)',
  'The income must be a number.': '$t(validations:field_must_be_number)',
  'The payout must be a number.': '$t(validations:field_must_be_number)',
  'The income field is required when income_currency is present.':
    'The income field is required when income_currency is present.',
  'The income must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The income may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The income_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The income_currency field is required when income is present.':
    'The income_currency field is required when income is present.',
  'The payout field is required when payout_currency is present.':
    'The payout field is required when payout_currency is present.',
  'The payout must be at least -2147483.':
    '$t(validations:the_value_cannot_be_less_than_-2_147_483).',
  'The payout may not be greater than 2147483.':
    '$t(validations:the_value_cannot_be_more_than_2_147_483).',
  'The payout_currency must be a string.':
    '$t(validations:field_must_be_string)',
  'The payout_currency field is required when payout is present.':
    'The payout_currency field is required when payout is present.',
  'The comment_to_partner must be a string.':
    '$t(validations:field_must_be_string)',
  'The comment_to_partner may not be greater than 1000 characters.':
    '$t(validations:maximum_length_1000_characters)',
  'Unknown status': 'Unknown status',
  'The currency is not activated.': 'The currency is not activated.',
  'The file must be a file of type: csv':
    'The file must be a file of type: csv',
  'The data has incorrect csv delimiters.':
    'The data has incorrect csv delimiters',
  'The file is empty.': 'The file is empty',
  'The promocode has another promocode type.':
    'Promo code type cannot be changed',
  'The promocode is personal.': 'The promo code is personal',
  'The admin panel url format is invalid.':
    'The admin panel url format is invalid.',
  'The partner panel url format is invalid.':
    'The admin panel url format is invalid.',
  'The advertiser panel url format is invalid.':
    'The admin panel url format is invalid.',
  'The field must be at least 2 characters.':
    'The field must be at least 2 characters.',
  'The field may not be greater than 2000 characters.':
    'The field may not be greater than 2000 characters.',
  choose_reasons: 'Reasons to hide must be chosen',
  'The old domain format is invalid.':
    'Enter the domain in the following format: https/http/example.com->example.com',
  'The domain format is invalid.':
    'Enter the domain in the following format: https/http/example.com->example.com',
  'You have reached the limit of 3 private dashboards.':
    'You have reached the limit of 3 private dashboards.',
  'Dashboard set for a partner cannot be private.':
    'Dashboard set for a partner cannot be private.',
  'The conversions must have at least 1 items.':
    'The file must contain at least one entry.',
  'The conversions may not have more than 1000 items.':
    'The file may not have more than 1000 entries.',
  'The selected partner_ids is invalid.': 'Inactive partner selected',
  'Invalid two factor code': 'Wrong code',
  'Invalid two factor GA code': 'Wrong code',
  'The selected filter.goal key is invalid.':
    'The selected goal key is invalid',
  image_wrong_type: 'Incorrect image type',
  color_near_error: 'The color cannot match the color of the adjacent sector',
  customers_statistics_goal_group_required: `Required if "$t(statistics:customers_cohort_period)" field is specified`,
  customers_statistics_cohort_period_goal_group_required: `You need to select a goal group`,
  gtm_prefix: 'Google Tag Manager ID must start with "GTM-"',
  product_formula_error:
    'It is not allowed to set terms in the form of ordinary numbers, variable coefficients, or the product of product goals in the formula string. It is related of the special things in calculating numerical sequences and calculating the formula with changes in the terms of payments and variable coefficients.',
  nnco_partners: 'For NNCO you need to select one, several or all partners',
};
