export default {
  yes: 'Да',
  no: 'Нет',
  delete: 'Удалить',
  log_in: 'Войти',
  'log out': 'Выйти',
  'my profile': 'Мой профиль',
  offer: 'Оффер',
  product: 'Продукт',
  active: 'Активный',
  update: 'Обновить',
  replace: 'Заменить',
  inactive: 'Неактивный',
  cancel: 'Отмена',
  to_cancel: 'Отменить',
  duplicate: 'Дублировать',
  partners: 'Партнёры',
  dashboard: 'Дашборд',
  'oops something went wrong here': 'Упс! Что-то пошло не так',
  'do you want to log out of your profile': 'Вы хотите выйти из профиля?',
  'save changes': 'Сохранить изменения',
  save: 'Сохранить',
  slug: 'Slug',
  settings: 'Настройки',
  create: 'Создать',
  add: 'Добавить',
  'an error has occurred': 'Произошла ошибка',
  enabled: 'Активно',
  disabled: 'Неактивно',
  error: 'Ошибка',
  access_denied: 'Доступ запрещен',
  continue: 'Продолжить',
  apply: 'Применить',
  apply_changes: 'Применить изменения',
  'removal was successful': 'Удаление прошло успешно',
  cancellation_successful: 'Отмена прошла успешно',
  copy: 'Копировать',
  copy_success: 'Текст был успешно скопирован',
  copy_success_any: 'Успешно скопировано',
  open: 'Открыть',
  min: 'min',
  max: 'max',
  edit_profile: 'Редактировать профиль',
  maximum: 'Максимум',
  edit: 'Изменить',
  edit2: 'Редактировать',
  date: 'Дата',
  id: 'ID',
  email: 'Email',
  status: 'Статус',
  count: 'Количество',
  count_short: 'Кол-во',
  amount: 'Сумма',
  total: 'Итого',
  supported_file_type: 'Поддерживаемый тип файлов',
  image_dimensions:
    'Максимальные размеры: {{width}}*{{height}} (ширина*высота)',
  image_min_dimensions:
    'Минимальные размеры: {{width}}*{{height}} (ширина*высота)',
  image_size: 'Максимальный размер изображения: {{size}}',
  image_uploading_error: 'Ошибка при загрузке изображения',
  role: 'Роль',
  manager: 'Менеджер',
  on_moderation: 'На модерации',
  date_or_period: 'Дата или период',
  main_page: 'Главная',
  products_list: 'Список продуктов',
  offers_list: 'Список офферов',
  admins_list: 'Список администраторов',
  partners_list: 'Список партнеров',
  advertisers_list: 'Список рекламодателей',
  paste_user_name: '{{user_name}}',
  paste_user_email: '{{user_email}}',
  paste_name: '{{name}}',
  paste_news_name: '{{news_name}}',
  paste_offer_name: '{{offer_name}}',
  paste_product_name: '{{product_name}}',
  product_new: 'Новый продукт',
  product_editing: 'Редактирование {{product_name}}',
  offer_new: 'Новый оффер',
  offer_editing: 'Редактирование {{offer_name}}',
  offer_conditions_editing: 'Редактирование условий',
  customers: 'Клиенты',
  statistics: 'Статистика',
  statistics_common: 'Основная статистика',
  statistics_event: 'Статистика по событиям',
  statistics_conversion: 'Статистика по конверсиям',
  statistics_qualification: 'Статистика по квалификациям',
  paste_statistics_name: '{{statistics_name}}',
  statistics_cohort: 'Когортный анализ',
  withdrawal_requests: 'Заявки на вывод средств',
  invoices: 'Счета',
  common_settings: 'Основные настройки',
  finance: 'Финансы',
  requests_for_access: 'Заявки на доступ',
  approve: 'Принять',
  reject: 'Отклонить',
  referrals: 'Рефералы',
  currency: 'Валюта',
  actions: 'Действия',
  close: 'Закрыть',
  per_1000: 'за 1000',
  no_data: 'Нет данных',
  confirmation: 'Подтверждение',
  accept: 'Принять',
  accepted: 'Принято',
  declined: 'Отклонено',
  ok: 'OK',
  logs: 'Логи',
  reload: 'Обновить',
  load_more: 'Загрузить ещё',
  export: 'Экспорт',
  default: 'По умолчанию',
  set_default: 'Использовать по умолчанию',
  click: 'Клик',
  confirm: 'Подтвердить',
  promo: 'Промоматериалы',
  promo_codes: 'Промокоды',
  download: 'Скачать',
  add_new_group: 'Добавить новую группу',
  and: 'и',
  to_export: 'Экспортировать',
  to_import: 'Импортировать',
  terms_of_use: 'Условия использования',
  privacy_policy: 'Политика конфиденциальности',
  tags: 'Теги',
  news: 'Новости',
  add_news: 'Добавить новость',
  edit_news: 'Изменить новость',
  info: 'Информация',
};
