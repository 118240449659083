import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification } from 'models/INotification';

const initialState: { notifications: INotification[] } = { notifications: [] };

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<INotification>) {
      state.notifications.push(action.payload);
    },
    removeNotificationById(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== id,
      );
    },
  },
});

export const { actions, reducer } = notifications;
export const { addNotification, removeNotificationById } = actions;
export default reducer;
