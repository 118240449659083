export default {
  new_landing: 'New landing',
  edit_landing: 'Edit landing',
  no_landings_list: 'No landings list',
  name: 'Name',
  target_link: 'Target link',
  target_link_hint:
    '{click_id} - unique click ID;\n{partner_id} - partner ID;\n{offer_id} - offer ID;\n{creative_id} - promo-material ID;\n{sub_id1..10} - SubId #1 - SubId #10.',
  name_of_landing: 'Name of landing',
  name_of_landing_hint: 'From 3 to 128 characters',
  URL: 'URL',
  url_preview: 'Preview URL',
  delete_landing: 'Delete landing',
  do_you_want_to_delete: 'Do you want to delete',
  landing: 'landing',
  type: 'Type',
  landing_successfully_deleted: 'Landing successfully deleted',
  landing_successfully_created: 'Landing successfully created',
  landing_successfully_updated: 'Landing successfully updated',
  private: 'Private',
  partners_help:
    'For selected partners in the tracking link this landing will be available',
  undated: 'Undated',
  undated_help:
    'If the checkbox is selected then the landing page will be active from the date specified in the left field.',
  is_enabled: 'Active',
  is_availible: 'Availible for partners',
  date_from: 'Active from',
  date_to: 'To',
  status_active: 'Active',
  status_inactive: 'Inactive',
};
