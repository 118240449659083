import React from 'react';
import { Provider } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import isEmpty from 'lodash/isEmpty';

import configureStore from 'core/redux/configureStore';
import Application from 'core/components/Application';
import Loading from 'components/Loading';
import { isPartner } from 'utils/userUtils';
import GoogleTagManager from 'components/snippets/GoogleTagManager';

const store = configureStore({});

function App() {
  const hostUrl = process.env.REACT_APP_MAIN_DOMAIN;
  const isInnerSite =
    !isEmpty(hostUrl) && window?.location?.host?.includes?.(`.${hostUrl}`);
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Helmet prioritizeSeoTags={true}>
          {isInnerSite && <meta name="robots" content="noindex,nofollow" />}
        </Helmet>
        {isPartner() && <GoogleTagManager />}
        <React.Suspense fallback={<Loading />}>
          <Application />
        </React.Suspense>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
