import { TFunction } from 'i18next';
import 'intl-pluralrules';
import { NavigateFunction } from 'react-router';
import isObject from 'lodash/isObject';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';
import forIn from 'lodash/forIn';
import find from 'lodash/find';
import get from 'lodash/get';

import { ICurrency } from 'models/ICurrency';

/**
 * Executes a given function asynchronously.
 *
 * @param {() => unknown} func - The function to be executed asynchronously.
 * @returns {Promise<unknown>} - A promise that resolves to the result of executing the function.
 */
export const callAsync = async (func: () => unknown) => {
  return func();
};

/**
 * Returns the value of a field in an object or the provided object if the field does not exist.
 *
 * @typeParam T - The type of the field value.
 *
 * @param {string} field - The field to retrieve from the object.
 * @param {any} [value] - The object from which to retrieve the field value. Optional, default is null.
 * @returns {T} - The value of the field if it exists, otherwise the provided object.
 */
export const getFieldOrValue =
  (field: string) =>
  <T = any>(value?: any): T =>
    get(value, field, value) as T;

/**
 * Get the value of the 'id' field or property from the given object.
 *
 * @param {any} object - The object from which to retrieve the 'id' value.
 * @returns {any} - The value of the 'id' field or property from the input.
 */
export const getId = getFieldOrValue('id');

export const getFieldOrValueLocalized =
  (t: TFunction, field: string) =>
  (value?: any): any =>
    t(get(value, field, value));

/**
 * Returns the value of a field in an object or undefined if the field does not exist.
 *
 * @typeParam T - The type of the field value.
 *
 * @param {string} field - The field to retrieve from the object.
 * @param {any} [value] - The object from which to retrieve the field value. Optional, default is null.
 * @returns {T | undefined} - The value of the field if it exists, otherwise undefined.
 */
export const getField =
  (field: string) =>
  <T = any>(value?: any): T | undefined =>
    get(value, field) as T | undefined;

export const isEmptyObject = (value: unknown): boolean =>
  isObject(value) && isEmpty(value);

/**
 * Converts a numeric enum object into an array of its numeric values.
 *
 * @template T - The type of the numeric enum.
 * @param {T} en - The numeric enum object.
 * @returns {Array<T[keyof T]>} - The array of numeric values from the enum.
 */
export const numericEnumToArray = <T>(en: T): Array<T[keyof T]> => {
  const result: Array<T[keyof T]> = [];
  forIn(en, (value) => {
    if (!isNaN(Number(value))) {
      result.push(value);
    }
  });
  return result;
};

/**
 * Converts a numeric enum object into an array of its string keys.
 *
 * @template T - The type of the numeric enum.
 * @param {T} en - The numeric enum object.
 * @returns {Array<keyof T>} - The array of string keys from the enum.
 */
export const numericEnumToKeysArray = <T>(en: T): Array<keyof T> => {
  const result: Array<keyof T> = [];
  forIn(en, (value, key) => {
    if (!isNaN(Number(value))) {
      result.push(key as keyof T);
    }
  });
  return result;
};

/**
 * Checks whether the current environment is a test environment.
 */
export const isTestEnvironment = (): boolean => {
  const host = window.location.host || '';
  return (
    host.startsWith('test-client.') ||
    host.startsWith('localhost:') ||
    host.endsWith('alanbase.test') ||
    host.endsWith('flansa.com')
  );
};

export const isMobileDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
export const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
};

/**
 * Opens the specified URL in a new browser tab.
 * If it is an iOS Safari, URL will be opened in the current tab,
 * because Safari blocks opening tabs in a new tab from an async function
 *
 * @param {string} href - The URL to open in a new tab.
 * @param {NavigateFunction} navigateFunction - result of the useNavigation hook from the react-router library.
 * @returns {void}
 */
export const openInNewTab = (
  href: string,
  navigateFunction?: NavigateFunction,
): void => {
  if (isSafari() && isMobileDevice()) {
    if (navigateFunction) {
      navigateFunction(href);
    } else {
      window.location.href = href;
    }
  } else {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: href,
    }).click();
  }
};

/**
 * Converts the value of a currency from one currency code to another.
 *
 * @param {number} value - The value of the currency to convert.
 * @param {ICurrency | string} oldCurrencyCode - The currency code of the original currency.
 * @param {ICurrency | string} newCurrencyCode - The currency code of the target currency.
 * @param {ICurrency[]} [currencies] - An optional array of currency objects to use for currency rates.
 * @returns {number} - The converted value of the currency.
 */
export const convertValueCurrency = (
  value: number,
  oldCurrencyCode: ICurrency | string,
  newCurrencyCode: ICurrency | string,
  currencies?: ICurrency[],
): number => {
  if (isEmpty(currencies)) return value;
  const getCurrencyCode = getFieldOrValue('currency_code');

  const oldCurrencyRate = toNumber(
    find(
      currencies,
      (c) => c.currency_code === getCurrencyCode(oldCurrencyCode),
    )?.rate ?? 1,
  );
  if (oldCurrencyRate === 0) return 0;
  const newCurrencyRate = toNumber(
    find(
      currencies,
      (c) => c.currency_code === getCurrencyCode(newCurrencyCode),
    )?.rate ?? 1,
  );
  return (value / oldCurrencyRate) * newCurrencyRate;
};

/**
 * Formats a given number as an ordinal number based on the specified locale.
 *
 * @param {number} n - The number to format as an ordinal number.
 * @param {string} locale - The locale to use for formatting the number.
 * @returns {string} - The formatted ordinal number.
 */
export const formatOrdinalNumber = (n: number, locale: string): string => {
  const pr = new Intl.PluralRules(locale, { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);
  const formatOrdinals = (n: number): string => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix ?? ''}`;
  };
  return formatOrdinals(n);
};

export async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    const inputArea = document.createElement('textarea');
    inputArea.value = text;
    document.body.appendChild(inputArea);
    inputArea.select();
    const success = document.execCommand('copy');

    document.body.removeChild(inputArea);
  }
}
/**
 * Return 53-bit hash for passed string
 */
export const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/**
 * Checks if the current browser is Safari.
 *
 * @type {boolean}
 * @description A regular expression is used to match the user agent string
 * against the pattern '/^((?!chrome|android).)*safari/i'.
 * If the user agent string contains the word "chrome" or "android",
 * the test will fail and return false, indicating that the browser is not Safari.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Browser_Name}
 */
export const isSafari = (): boolean =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const canUseMobileLayout = (): boolean => {
  // return isTestEnvironment();
  const host = window.location.host || '';
  return (
    host.startsWith('test-client.') ||
    host.startsWith('demo-cpa.') ||
    host.startsWith('localhost:') ||
    host.startsWith('127.0.0.1:') ||
    host.startsWith('192.168.1.4:') ||
    host.startsWith('lamoda.admin.alanbase.test') ||
    host.startsWith('lamoda.partner.alanbase.test') ||
    host.startsWith('lamoda.advertiser.alanbase.test') ||
    host.startsWith('admin.alanbase.test') ||
    host.startsWith('partner.alanbase.test') ||
    host.startsWith('advertiser.alanbase.test') ||
    // host.endsWith('alanbase.test') ||
    host.endsWith('flansa.com')
  );
};
