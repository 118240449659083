export default {
  new_landing: 'Новый лендинг',
  edit_landing: 'Редактировать лендинг',
  no_landings_list: 'Нет списка созданных лендингов',
  name: 'Название',
  target_link: 'Target-ссылка',
  target_link_hint:
    '{click_id} - уникальный идентификатор клика;\n{partner_id} - ID партнера;\n{offer_id} - ID оффера;\n{creative_id} - идентификатор промо-материала;\n{sub_id1..10} - SubId #1 - SubId #10.',
  name_of_landing: 'Название лендинга',
  name_of_landing_hint: 'От 3 до 128 символов',
  URL: 'URL',
  url_preview: 'Превью URL',
  delete_landing: 'Удалить лендинг',
  do_you_want_to_delete: 'Вы хотите удалить',
  landing: 'лендинг',
  type: 'Тип',
  landing_successfully_deleted: 'Лендинг успешно удален',
  landing_successfully_created: 'Лендинг успешно создан',
  landing_successfully_updated: 'Лендинг успешно обновлен',
  private: 'Приватный',
  partners_help:
    'Для выбранных партнеров в трекинг-ссылке будет доступен данный лендинг',
  undated: 'Неограниченно',
  undated_help:
    'Если отмечено, то лендинг будет активен с даты, указанной в левом поле.',
  is_enabled: 'Активен',
  is_availible: 'Доступен для партнеров',
  date_from: 'Активен с',
  date_to: 'По',
  status_active: 'Активен',
  status_inactive: 'Не активен',
};
