import { takeEvery, delay, put } from 'redux-saga/effects';
import {
  removeNotificationById,
  addNotification,
} from 'core/redux/reducers/notifications';
import { IAction } from 'models/IAction';
import { INotification } from 'models/INotification';

const DEFAULT_TIMEOUT = 5000;

function* handleAddNotification({ payload }: IAction<INotification>) {
  const { id, timeout = DEFAULT_TIMEOUT }: INotification = payload;

  if (timeout) {
    yield delay(timeout);
    yield put(removeNotificationById(id));
  }
}

export default [takeEvery(addNotification.type, handleAddNotification)];
