import {
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { Location } from '@remix-run/router';
import queryString from 'query-string';
import { AnyAction } from 'redux';
import i18next from 'i18next';
import uniqid from 'uniqid';
import defaultsDeep from 'lodash/defaultsDeep';
import isFunction from 'lodash/isFunction';
import defaultTo from 'lodash/defaultTo';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import assign from 'lodash/assign';
import forIn from 'lodash/forIn';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import get from 'lodash/get';
import set from 'lodash/set';

import { fetchSaga } from './fetchSaga';
import { redirectToLogin, redirectToNotFoundPage } from './appSaga';
import {
  ModelChangeOptionsType,
  modelDelete,
  modelPush,
  modelUpdate,
  setModel,
} from 'core/redux/reducers/models';
import {
  filterMapToURL,
  handleAddInMeta,
  handleDeleteInMeta,
  registerWidget,
  setError,
  setFilterMappingFromUrlDone,
  setFilters,
  setLoading,
  setMeta,
  widgetRequest,
} from 'core/redux/reducers/widgets';
import { getWidgetById, getWidgetFilter } from 'core/redux/selectors/widget';
import { addNotification as addNotificationAction } from 'core/redux/reducers/notifications';
import { NotificationType } from 'constants/enums/NotificationType';
import { ResponseStatus } from 'constants/ResponseStatus';

import { IResponseHandler } from 'models/widget/IResponseHandler';
import { IWidgetFetchOptions } from 'models/widget/IWidgetFetchOptions';
import { IWidget } from 'models/widget/IWidget';
import { IApiDto } from 'models/IApiDto';
import { IApiError, IError } from 'models/IApiErrorDto';
import {
  collectFlattenQueryMapping,
  stringifyQuery,
} from 'core/utils/fetchUtils';
import { mergeObjects } from 'utils/objectUtils';
import schedulerApi from 'utils/SchedulerApi';
import { IFilter } from 'models/widget/IFilter';

const ResponseHandlers: { [key: string]: any } = {
  MODEL_SET: setModel,
  MODEL_PUSH: modelPush,
  MODEL_DELETE: modelDelete,
  MODEL_UPDATE: modelUpdate,
};

const ResponseMetaHandlers: { [key: string]: any } = {
  MODEL_PUSH: handleAddInMeta,
  MODEL_DELETE: handleDeleteInMeta,
};

const replace = (navigate: NavigateFunction, location: Partial<Location>) => {
  try {
    let url = `${location.pathname}${location.search ?? ''}`;
    if (!isEmpty(location.hash)) {
      const prefix = toString(location.hash).startsWith('#') ? '' : '#';
      url = `${url}${prefix}${location.hash}`;
    }
    navigate(url, { replace: true });
  } catch (e) {
    console.error(e);
  }
};

/**
 * Parses the query string from a given URL.
 * Automatically parses numbers and boolean values.
 * If number is too large, parses it as a string. @see issue PP-7326
 *
 * @param {string} url - The URL containing the query string.
 * @return {object} - An object representing the parsed query string.
 */
function parseQueryString(url: string) {
  const parsedQuery = queryString.parse(url, {
    arrayFormat: 'bracket',
    parseNumbers: true,
    parseBooleans: true,
  });
  const parsedStringQuery = queryString.parse(url, {
    arrayFormat: 'bracket',
    parseNumbers: false,
    parseBooleans: true,
  });

  forIn(parsedQuery, (value, key) => {
    if (
      Number.isNaN(value) ||
      (isNumber(value) &&
        (value > Number.MAX_SAFE_INTEGER || value < Number.MIN_SAFE_INTEGER))
    ) {
      parsedQuery[key] = parsedStringQuery[key];
    }
  });

  return parsedQuery;
}

function* mapUrlToRedux({ payload }: ReturnType<typeof registerWidget>) {
  const { widgetId, options } = payload;
  const filter = options?.filter as IFilter | undefined;
  const location = window.location;
  const filterWidgetId = filter?.widgetId || widgetId;
  const widget: IWidget = yield select(getWidgetById(filterWidgetId));

  if (!widget?.filter?.mappingFromUrlDone) {
    if (filter?.keysToMapFromUrl) {
      const parsedQuery: ReturnType<typeof parseQueryString> =
        yield schedulerApi.backgroundTask(function parseQueryStringFunc() {
          return parseQueryString(location.search);
        });
      const collectedQuery: ReturnType<typeof collectFlattenQueryMapping> =
        yield schedulerApi.backgroundTask(function collectQuery() {
          return collectFlattenQueryMapping(defaultTo(parsedQuery, {}));
        });
      const filterValues: Record<any, any> = yield schedulerApi.backgroundTask(
        function mergeFilterValues() {
          return mergeObjects(
            filter.initialValues,
            widget?.filter?.values,
            ['filter'],
            true,
          );
        },
      );
      const pickedValues: Record<any, any> = yield schedulerApi.backgroundTask(
        function pickFilterValues() {
          return mergeObjects(
            filterValues,
            filter?.keysToMapFromUrl === 'ALL'
              ? collectedQuery
              : pick(collectedQuery, filter.keysToMapFromUrl as string[]),
            ['filter'],
            true,
          );
        },
      );
      yield put(setFilters({ widgetId: filterWidgetId, filter: pickedValues }));
      yield (yield schedulerApi.backgroundTask(function setMappingStatus() {
        return put(
          setFilterMappingFromUrlDone({
            widgetId: filterWidgetId,
            isDone: true,
          }),
        );
      })) as PutEffect;
    }
  }
}

function* setFilterToUrl({ payload }: ReturnType<typeof filterMapToURL>) {
  const widget: IWidget = yield select(getWidgetById(payload.widgetId));
  const location = window.location;
  const navigate = payload.navigate;
  if (isEmpty(widget)) return;

  const filterWidget: IWidget = widget.filter?.widgetId
    ? yield select(getWidgetById(widget.filter?.widgetId))
    : widget;

  if (!widget.filter.keysToMapFromUrl) return;

  const filter: Record<string, any> = yield schedulerApi.backgroundTask(
    function pickFilter() {
      const values = defaultTo(
        get(payload, 'filter', null),
        isEmpty(filterWidget?.filter?.values)
          ? widget.filter.initialValues
          : filterWidget.filter.values,
      );
      if (widget.filter.keysToMapFromUrl === 'ALL') {
        return values;
      }
      return pick(values, widget.filter.keysToMapFromUrl as Array<string>);
    },
  );
  schedulerApi.backgroundTask(function replaceUrl() {
    replace(navigate, {
      pathname: location.pathname,
      search: `?${stringifyQuery(filter)}`,
      hash: location.hash,
    });
  });
}

function* handleWidgetRequest({ payload }: ReturnType<typeof widgetRequest>) {
  const {
    widgetId,
    dataProvider,
    location,
    navigate,
    options: payloadOptions = {},
  } = payload;
  const defaultResponseHandler = {
    modelId: null,
    actionType: 'MODEL_SET',
    modelFieldId: 'id',
    placeToSet: 'bottom',
    model: {},
    modelToSet: widgetId,
  } as IResponseHandler;
  const {
    onSuccess,
    onError,
    beforeRequest,
    afterRequest,
    initialData,
    ...options
  } = payloadOptions as IWidgetFetchOptions;
  const responseHandler: IResponseHandler = assign(
    defaultResponseHandler,
    payloadOptions?.responseHandler,
  );

  const responseActionHandler: undefined | typeof setModel =
    ResponseHandlers[responseHandler.actionType];
  const responseActionMetaHandler =
    ResponseMetaHandlers[responseHandler.actionType];
  let datastore = responseHandler.model
    ? responseHandler.model
    : assign({}, options.body, {
        [responseHandler.modelFieldId || 'id']: responseHandler.modelId,
      });
  const responseAction = function* (datastore: any, error?: any, meta?: any) {
    const actionModelId = responseHandler.modelToSet || widgetId;
    const actionDatasource =
      responseHandler.modelMapper && isFunction(responseHandler.modelMapper)
        ? responseHandler.modelMapper(datastore, !!error)
        : datastore;
    const actionOptions: ModelChangeOptionsType = {
      modelFieldId: responseHandler.modelFieldId,
      path: responseHandler.pathToSet,
      modelId: responseHandler.modelId,
      place: responseHandler.placeToSet,
    };

    yield (yield schedulerApi.backgroundTask(function putActions() {
      return put(
        batchActions(
          compact([
            responseHandler.actionType !== 'SKIP' &&
              responseActionHandler?.({
                modelId: actionModelId,
                datasource: actionDatasource,
                options: actionOptions,
              }),
            dataProvider.method === 'GET' &&
              meta &&
              setMeta({ widgetId, meta }),
            responseActionMetaHandler &&
              responseActionMetaHandler(actionModelId),
            setError({ widgetId, error }),
          ]),
        ),
      );
    })) as PutEffect;
  };

  try {
    yield (yield schedulerApi.blockingTask(function changeLoadingStatus() {
      return put(setLoading({ widgetId: payload.widgetId, isLoading: true }));
    })) as PutEffect;

    if (!isNil(initialData)) {
      yield (yield schedulerApi.backgroundTask(function setInitialModel() {
        return put(
          setModel({ modelId: payload.widgetId, datasource: initialData }),
        );
      })) as PutEffect;
    }
    const filter: IFilter = yield select(getWidgetFilter(widgetId));
    const filterWidget: IFilter = filter?.widgetId
      ? yield select(getWidgetFilter(filter.widgetId))
      : filter;

    if (!isEmpty(filter)) {
      yield schedulerApi.backgroundTask(function setOptions() {
        set(
          options,
          'queryMapping',
          defaultsDeep(options.queryMapping, {
            ...assign({}, filter.initialValues, filterWidget.values),
          }),
        );
      });
    }

    if (beforeRequest && isFunction(beforeRequest)) {
      yield schedulerApi.backgroundTask(function beforeRequestCallback() {
        beforeRequest(widgetId);
      });
    }
    const responsePromise = (yield schedulerApi.backgroundTask(
      function fetchData() {
        return call(fetchSaga, dataProvider, options);
      },
    )) as CallEffect;
    if (afterRequest && isFunction(afterRequest)) {
      yield schedulerApi.backgroundTask(function afterRequestCallback() {
        afterRequest(widgetId);
      });
    }
    const response: IApiDto = yield responsePromise;
    const { data, meta } = response || {};

    if (data) {
      datastore = data as IApiDto;
    } else if (response && !isEmpty(response)) {
      datastore = response;
    }

    yield (yield schedulerApi.backgroundTask(function performResponseAction() {
      return responseAction(datastore, null, meta);
    })) as PutEffect;

    if (isFunction(onSuccess)) {
      yield schedulerApi.backgroundTask(function successCallback() {
        onSuccess(datastore, widgetId, meta);
      });
    }
  } catch (err: any) {
    if (!responseHandler?.notRedirect) {
      if (err.status === ResponseStatus.Unauthorized) {
        redirectToLogin();
      } else if (err.status === ResponseStatus.NotFound) {
        redirectToNotFoundPage();
        return;
      }
    }

    console.error('Widget Saga Error:');
    console.error(err);
    if (responseHandler?.setOnError) {
      yield (yield schedulerApi.backgroundTask(
        function performResponseAction() {
          return responseAction(datastore, err.json as IApiError);
        },
      )) as PutEffect;
    } else {
      yield (yield schedulerApi.backgroundTask(function setErrorMessage() {
        return put(setError({ widgetId, error: err.json as IApiError }));
      })) as PutEffect;
    }

    if (!responseHandler.hideErrorMessages && err.body) {
      const response: Record<string, any> = yield schedulerApi.backgroundTask(
        function parseResponseJson() {
          return JSON.parse(err.body);
        },
      );

      if (response?.errors) {
        const errorsActions: AnyAction[] = [];

        forEach(response.errors, (e: IError) => {
          if (e?.message && !e.source) {
            errorsActions.push(
              addNotificationAction({
                id: uniqid(),
                type: NotificationType.DANGER,
                message: i18next.t(`validations::${e.message as string}`, {
                  nsSeparator: '::',
                }),
                timeout: 5000,
              }),
            );
          }
        });

        if (errorsActions.length > 0) {
          yield (yield schedulerApi.backgroundTask(
            function showErrorMessages() {
              return put(batchActions(compact(errorsActions)));
            },
          )) as PutEffect;
        }
      }
    }
    if (isFunction(onError)) {
      yield schedulerApi.backgroundTask(function errorCallback() {
        onError(err, widgetId);
      });
    }
  } finally {
    yield (yield schedulerApi.blockingTask(function resetLoading() {
      return put(setLoading({ widgetId, isLoading: false }));
    })) as PutEffect;
    yield (yield schedulerApi.backgroundTask(function resetLoading() {
      return put(filterMapToURL({ widgetId, navigate, location }));
    })) as PutEffect;
  }
}

export default [
  takeEvery(registerWidget.type, mapUrlToRedux),
  takeEvery([filterMapToURL.type], setFilterToUrl),
  takeEvery(widgetRequest.type, handleWidgetRequest),
];
