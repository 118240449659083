import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import some from 'lodash/some';
import map from 'lodash/map';

import { IOfferPartnersState } from 'models/IOfferPartnersState';
import { IOfferPartnerRequest } from 'models/IOfferPartnerRequest';
import { PreModerationStatus } from 'constants/enums/PreModerationStatus';

export const initialOfferPartnersState: IOfferPartnersState = {
  requests: [],
  isLoading: false,
};

const changeRequestsState = (
  requestIds: number[],
  status: PreModerationStatus | null,
  requests?: IOfferPartnerRequest[] | null,
): IOfferPartnerRequest[] => {
  const changedRequests = map(requests, (request) => {
    const exist = some(requestIds, (id) => id === request.id);
    if (exist) {
      return {
        ...request,
        premoderate_status: status,
      };
    }
    return request;
  });
  return changedRequests;
};

const changeRequestsLoading = (
  requestIds: number[],
  loading: boolean,
  requests?: IOfferPartnerRequest[] | null,
): IOfferPartnerRequest[] => {
  const changedRequests = map(requests, (request) => {
    const exist = some(requestIds, (id) => id === request.id);
    if (exist) {
      return {
        ...request,
        isLoading: loading,
      };
    }
    return request;
  });
  return changedRequests;
};

const offerPartners = createSlice({
  name: 'offerPartners',
  initialState: initialOfferPartnersState,
  reducers: {
    fetchRequests(
      state,
      action: PayloadAction<{
        offerId: number;
        offerWidgetId: string;
        endpoints: IOfferPartnersState['endpoints'];
      }>,
    ) {
      state.offerId = action.payload.offerId;
      state.offerWidgetId = action.payload.offerWidgetId;
      state.endpoints = action.payload.endpoints as any;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setRequestsLoading(
      state,
      action: PayloadAction<{
        ids: number[];
        isLoading: boolean;
      }>,
    ) {
      state.requests = changeRequestsLoading(
        action.payload.ids,
        action.payload.isLoading,
        state.requests,
      );
    },
    setRequestsStatus(
      state,
      action: PayloadAction<{
        ids: number[];
        status: PreModerationStatus | null;
      }>,
    ) {
      state.requests = changeRequestsState(
        action.payload.ids,
        action.payload.status,
        state.requests,
      );
    },
    setRequests(state, action: PayloadAction<IOfferPartnerRequest[]>) {
      state.requests = action.payload;
    },
    approveRequest(state, action: PayloadAction<{ requestIds: number[] }>) {},
    rejectRequest(state, action: PayloadAction<{ requestIds: number[] }>) {},
  },
});

export const { actions, reducer } = offerPartners;
export const {
  rejectRequest,
  setRequestsLoading,
  setRequestsStatus,
  approveRequest,
  setRequests,
  setLoading,
  fetchRequests,
} = actions;
export default reducer;
